export const getChannel = (pathname) =>
  pathname?.match(/fbm/gi) ? "fbm" : pathname?.match(/sms/gi) ? "sms" : "web";

export const getItemName = (input = "", start) => {
  // Find the index of the first "-" character
  const dashIndex = input.indexOf("-");

  // If "-" is found, return the substring before it; otherwise, return the input string as is
  if (dashIndex !== -1) {
    const result = input.substring(0, dashIndex).trim();
    return result;
  } else {
    return input;
  }
};

export function formatNumberWithQuestionMark(number) {
  const numberString = number.toFixed(2);

  function replaceAllButFirstCharWithQuestionMarks(inputString) {
    return inputString[0] + "?".repeat(inputString.length - 1);
  }

  const integer = `${replaceAllButFirstCharWithQuestionMarks(numberString.split(".")[0])}`;
  const decimal = numberString.split(".")[1];

  return `${integer.length > 1 ? integer : "?"}.${decimal}`;
}

export const formatMobileNumber = (mobileNumber) => {
  if (mobileNumber) {
    // Remove any non-numeric characters
    const numericPhoneNumber = mobileNumber.replace(/\D/g, "");

    if (numericPhoneNumber.length >= 4) {
      // Use regex to format the phone number with spaces
      if (numericPhoneNumber.length <= 7) {
        return numericPhoneNumber.replace(/(\d{4})(\d+)/, "$1 $2");
      } else {
        return numericPhoneNumber.replace(/(\d{4})(\d{3})(\d+)/, "$1 $2 $3");
      }
    }
  }

  // Handle invalid or incomplete phone number format
  return mobileNumber;
};

export const fullStoryRandomizer = () => {
  let runFullStory = sessionStorage.getItem("runFullStory") === 'true';

  if (!runFullStory) {
    runFullStory = Math.random() < 0.03;
    sessionStorage.setItem("runFullStory", runFullStory);
  }
  return runFullStory;
};
