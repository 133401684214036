import { Grid } from "@mui/material";
import confirm_button from "../../assets/images/confirm_button.svg";
import Item from "./Item";
import { useWidth } from "./helperFunc/useWidth";

const ConfirmOrderButton = ({ label = "label", action }) => {
  const { width } = useWidth();

  return (
    <>
      <Grid container sx={{ height: 80 }} />
      <Grid
        container
        className="pointer pointer_touch2"
        onClick={action}
        sx={{
          left: 0,
          bottom: 0,
          height: 72.5,
          position: "fixed",
          background: `#FCF4F3`,
          backgroundImage: `url(${confirm_button})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          borderRadius: "12px 12px 0px 0px",
        }}>
        <Grid container className="tx700 tx95 c5" justifyContent="flex-end" alignItems="center">
          <Item sx={{ letterSpacing: "0.75px", px: width < 360 ? 4 : 5 }}>{label}</Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmOrderButton;
