import { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import Item from "../atoms/Item";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useReactiveVar } from "@apollo/client";
import { cartItemsVar, totalVar } from "../../graphql/reactiveVars";
import { CloseIcon } from "../../assets/icon";
import Notification, { notify } from "../atoms/Notification";
import { useModal } from "../atoms/helperFunc/useModal";
import ModalBox from "../atoms/ModalBox";
import NextButton from "../atoms/NextButton";
import { useNavigate } from "react-router-dom";
import { setCartItems } from "../../graphql/actions/cartActions";
import { useAnalytics, PageLabels } from "../../analytics";

const CartItem = ({ data = {} }) => {
  const { fireConfirmOrderEvent, fireChangeQuantityEvent } = useAnalytics({
    pageLabel: PageLabels.MY_CART,
  });
  const [count, setCount] = useState(data.qty);
  const cartItems = useReactiveVar(cartItemsVar);
  const { modal, setModal } = useModal(false);
  const navigate = useNavigate();

  const addCartItem = (data) => {
    const updatedArr = [...cartItems];
    const existingItemIndex = cartItems.findIndex((item) => item.id === data.id);

    if (updatedArr[existingItemIndex]?.qty >= 50) {
      return notify({
        message: "Maximum of 50 items are allowed.",
        severity: "warning",
      });
    }

    if (existingItemIndex !== -1) {
      updatedArr[existingItemIndex].qty += 1;
    } else {
      updatedArr.push({ ...data, qty: 1 });
    }

    setCount(count + 1);
    setCartItems(updatedArr);
  };

  const removeCartItem = (itemId) => {
    const updatedArr = [...cartItems];
    const existingItemIndex = cartItems.findIndex((existingItem) => existingItem.id === itemId);

    if (count <= 0) return;
    if (count === 1) return setModal(true);

    if (updatedArr[existingItemIndex].qty <= 1) {
      updatedArr.splice(existingItemIndex, 1); // Remove item from array
    } else {
      updatedArr[existingItemIndex].qty -= 1;
    }

    setCount(count - 1);
    setCartItems(updatedArr); // localforage + G.Analytics
  };

  const removeItemRecord = () => {
    setModal(true);
  };

  const confirmRemoval = ({ id, display_name }) => {
    const updatedArr = cartItems.filter((x) => x.id !== id);
    if (!updatedArr.length) {
      totalVar(0);
    }
    setCartItems(updatedArr);
    notify({
      message: `Removed “${display_name}” from your order list`,
      severity: "success",
    });
  };

  return (
    <Item sx={{ bgcolor: "#fff", height: "105px" }} className="tx85" px={1.75} py={2}>
      <Notification />

      <ModalBox modal={modal} setModal={setModal} sx={{ textAlign: "center", py: 3 }}>
        <Item className="tx700 tx100" pt={1}>
          Remove item from order list?
        </Item>

        <Item
          className="tx95 c4"
          sx={{
            letterSpacing: "0.75px",
            // lineHeight: "1.5rem"
            lineHeight: "24px",
          }}>
          {`Nais mo bang tanggalin ang “${data.display_name}” mula sa iyong order list?`}
        </Item>

        <Grid container gap={1.5} pt={1.75}>
          <Item flex={1}>
            <Button
              variant="outlined"
              fullWidth
              className="btn_orange capitalize"
              onClick={() => setModal(false)}>
              Cancel
            </Button>
          </Item>

          <Item flex={1}>
            <Button
              variant="contained"
              className="capitalize"
              fullWidth
              sx={{ p: "0.5rem" }}
              onClick={() => {
                confirmRemoval(data);
                fireChangeQuantityEvent(data.display_name, 0, PageLabels.MY_CART);
              }}>
              remove
            </Button>
          </Item>
        </Grid>
      </ModalBox>

      <Grid container spacing={1.5}>
        <Item>
          <Item
            sx={{
              minWidth: "64px",
              minHeight: "64px",
              bgcolor: "#EFF0F6",
              backgroundImage: `url("${data.image_url}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "2px",
              opacity: 0.95,
            }}
          />
        </Item>

        <Item flex={1} className="tx600 tx80">
          <Item
            sx={{
              minHeight: "52px",
              lineHeight: "22px",
            }}>
            <Grid container justifyContent="space-between">
              <Item flex={1} pr={2.5}>
                {data.display_name}
              </Item>

              <Item my={-0.75} mx={-0.5}>
                <IconButton size="small" onClick={removeItemRecord}>
                  <CloseIcon />
                </IconButton>
              </Item>
            </Grid>
          </Item>

          <Item className="c1 tx700 tx95" mt={-0.5}>
            <Grid container justifyContent="space-between" alignItems="center" pb={2}>
              <Item sx={{ letterSpacing: "0.75px" }}>₱{data.price?.toFixed(2)}</Item>

              <Item mt={-0.25}>
                <Grid container gap={0.75} alignContent="center" px={0.5}>
                  <Item
                    p={0.75}
                    // className="red"
                    onClick={() => {
                      removeCartItem(data.id);
                      count > 1 &&
                        fireChangeQuantityEvent(data.display_name, count - 1, PageLabels.MY_CART);
                    }}>
                    <IconButton
                      size="small"
                      className={count > 0 ? "hover_gray" : "disabled"}
                      disabled={count <= 0}
                      sx={{ bgcolor: "#DCF2F1" }}>
                      <RemoveIcon sx={{ height: 15, width: 15 }} />
                    </IconButton>
                  </Item>

                  <Item
                    my={0.25}
                    className="c4"
                    sx={{
                      width: "28px",
                      textAlign: "center",
                      margin: "auto",
                    }}>
                    {count}
                  </Item>

                  <Item
                    p={0.75}
                    // className="red"
                    onClick={() => {
                      addCartItem(data);
                      fireChangeQuantityEvent(data.display_name, count + 1, PageLabels.MY_CART);
                    }}>
                    <IconButton
                      size="small"
                      variat="contained"
                      className={count < 50 ? "hover_green" : "disabled"}
                      disabled={count >= 50}
                      sx={{ bgcolor: "#07A69C", color: "#fff" }}>
                      <AddIcon sx={{ height: 15, width: 15 }} />
                    </IconButton>
                  </Item>
                </Grid>
              </Item>
            </Grid>
          </Item>
        </Item>
      </Grid>
      <NextButton
        label="Pwede na ako tawagan"
        width="150px"
        action={(e) => {
          navigate(`/confirm-order`);
          fireConfirmOrderEvent();
        }}
      />
    </Item>
  );
};

export default CartItem;
