import { Box, Grid, Stack } from "@mui/material";
import Item from "../atoms/Item";
import add_item_banner from "../../assets/images/add_item_banner2.svg";
import { additionItemModalVar, sourceVar } from "../../graphql/reactiveVars";
import { useReactiveVar } from "@apollo/client";
import { useState } from "react";

const AddOtherItemsSmall = ({ source, skuList }) => {
  const additionItemModal = useReactiveVar(additionItemModalVar);
  const [touch, setTouch] = useState(false);

  const showModal = () => {
    additionItemModalVar(!additionItemModal);
    sourceVar(source);
  };

  return (
    <Box sx={{ height: 80 }} px={1} py={0.25} className={`pointer ${touch && "pointer_touch"}`}>
      <Grid
        container
        onTouchStart={() => setTouch(true)}
        onTouchEnd={() => setTouch(false)}
        onClick={showModal}
        alignItems="center"
        gap={{ xs: 0.5, sm: 1 }}
        sx={{
          height: 60,
          background: `#FFF`,
          backgroundImage: `url(${add_item_banner})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "left",
          borderRadius: "8px",
          border: "1px solid #C5BFDB",
        }}>
        <Item flex={1} pl="55px">
          <Stack px={1.5} pr={0} spacing={0.25} alignItems="start">
            <Item className={`tx600 tx80`} flex={1}>
              May iba pang hinahanap?
            </Item>
            <Item
              className="tx700 tx80"
              sx={{
                textAlign: "center",
                lineHeight: "16px",
                color: "#07A69C",
              }}>
              Add other items
            </Item>
          </Stack>
        </Item>
      </Grid>
    </Box>
  );
};

export default AddOtherItemsSmall;
