import { CircularProgress, Box } from "@mui/material";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";

export function FacebookCircularProgress(props) {
  const [color, setColor] = useState();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor((prevColor) => (!prevColor ? "#07A69C" : null));
    }, 600);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  return (
    <Box>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#fff",
          // color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={60}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          animationDuration: "600ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          color: color,
        }}
        size={60}
        thickness={5}
        {...props}
      />
    </Box>
  );
}

export const ProgressBar = ({ loading, type }) => {
  return (
    loading && (
      <Box position="fixed" top="45vh" sx={{ left: "50vw" }} mx={-2.5} zIndex={1}>
        <FacebookCircularProgress />
      </Box>
    )
  );
};

export default ProgressBar;
