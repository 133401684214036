import { cartAdditionItemsVar, cartItemsVar, clientInfoVar, loadingLocalVar } from "../reactiveVars";
import { skuListVar, totalVar } from "../reactiveVars";
import batch1 from "../../assets/data/products1.json";
import batch2 from "../../assets/data/products2.json";
import batch3 from "../../assets/data/products3.json";
import batch4 from "../../assets/data/products4.json";
import batch5 from "../../assets/data/products5.json";
import batch6 from "../../assets/data/products6.json";
import batch7 from "../../assets/data/products7.json";
import { cleanForage, getForage } from "../forage";

export const checkError = (error) => {
  console.log(
    `%c ${error?.response?.data?.error?.message || error || "Server Error"} `,
    "background:  #66ff66"
  );
  if (error?.response?.data?.error?.message) {
    throw new Error(error?.response?.data?.error?.message);
  } else {
    throw new Error(error || "Status 500, Server Error");
  }
};

export const getLocalData = async () => {
  loadingLocalVar(true);
  
  try {
    // CART ITEMS
    const localData = (await getForage("cartItems")) || [];

    const mergeLocal = (batch) =>
      batch?.flatMap((b) => {
        const localVal = localData.find((local) => local.id === b.id);
        return localVal ? localVal : b;
      });

    // Attached localforage data(qty) to BATCH1 list
    const updatedSKU = localData ? await mergeLocal(batch1) : batch1;

    await skuListVar(updatedSKU);
    await cartItemsVar(localData || []);
    // ADDITIONAL CART ITEMS
    const additionCartItemsLocal = await getForage("additionCartItems");
    cartAdditionItemsVar(additionCartItemsLocal);

    // CLIENT INFO
    const clientInfo = (await getForage("clientInfoItems")) || {
      full_name: "",
      mobile_number: "",
      complete_address: "",
    };

    clientInfoVar(clientInfo);

    // GET TOTAL COST
    const totalCost = await getForage("totalCost");
    totalVar(totalCost);
    loadingLocalVar(false);

    // next batch2-4 products
    const batch2SKU = localData ? await mergeLocal(batch2) : batch2;
    skuListVar([...batch2SKU, ...skuListVar()]);
    const batch3KU = localData ? mergeLocal(batch3) : batch3;
    skuListVar([...batch3KU, ...skuListVar()]);
    const batch4KU = localData ? mergeLocal(batch4) : batch4;
    skuListVar([...batch4KU, ...skuListVar()]);
    const batch5KU = localData ? mergeLocal(batch5) : batch5;
    skuListVar([...batch5KU, ...skuListVar()]);
    const batch6KU = localData ? mergeLocal(batch6) : batch6;
    skuListVar([...batch6KU, ...skuListVar()]);
    const batch7KU = localData ? mergeLocal(batch7) : batch7;
    skuListVar([...batch7KU, ...skuListVar()]);
  } catch (e) {
    loadingLocalVar(false);
    checkError(e);
  }
};

export const updateSKUsOnCart = () => {
  const skuList = skuListVar();
  const cartItems = cartItemsVar();
  const updateSkuList = skuList.map((sku) => {
    const updatedQty = cartItems.find((cart) => cart.product_template_id === sku.product_template_id);
    const newArr = updatedQty ? { ...sku, qty: updatedQty.qty } : { ...sku, qty: 0 };
    return newArr;
  });
  skuListVar(updateSkuList);
};

export const clearData = () => {
  const skuList = skuListVar();
  const updateSkuList = skuList.map((sku) => ({ ...sku, qty: 0 }));
  skuListVar(updateSkuList);

  cleanForage();
  cartItemsVar([]);
  clientInfoVar({ full_name: "", mobile_number: "", complete_address: "" });
  cartAdditionItemsVar("");
  totalVar(0);
};
