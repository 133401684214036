import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { Box, SwipeableDrawer, Stack, Button, IconButton } from "@mui/material";
import Item from "./Item";
import Notification, { notify } from "./Notification";
import { CloseIcon } from "../../assets/icon";
import { useReactiveVar } from "@apollo/client";
import { additionItemModalVar, cartAdditionItemsVar } from "../../graphql/reactiveVars";
import { summaryModalVar, sourceVar } from "../../graphql/reactiveVars";
import TextInput from "./TextInput";
import { setAdditionalCartItems } from "../../graphql/actions/cartActions";
import { useEffect, useState } from "react";
import { useAnalytics } from "../../analytics";
import { capitalize } from "lodash";

function SwipeableDrawer2() {
  const { fireAnswerSurveyEvent, fireUserInputEvent } = useAnalytics({
    pageLabel: sessionStorage.getItem("CurrentPage"),
  });
  const [defaultVal, setDefaultVal] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [answer, setAnswer] = useState("");
  const additionItemModal = useReactiveVar(additionItemModalVar);
  const cartAdditionItems = useReactiveVar(cartAdditionItemsVar);
  const source = useReactiveVar(sourceVar);

  const toggleDrawer = (newOpen) => () => {
    summaryModalVar(!newOpen);
    additionItemModalVar(newOpen);
  };

  const onSave = () => {
    fireUserInputEvent(sessionStorage.getItem("CurrentPage"), answer, "other_items", source);
    additionItemModalVar(false);
    summaryModalVar(true);
    setDefaultVal(cartAdditionItems);
    setAdditionalCartItems(inputVal);
    inputVal?.length &&
      notify({
        message: "Added other items to your order.",
        severity: "success",
      });
  };

  useEffect(() => {
    setInputVal(cartAdditionItems);
    setDefaultVal(cartAdditionItems);
  }, [cartAdditionItems]);

  useEffect(() => {
    if (additionItemModal && source.match(/end of list pull/i)) {
      window.scrollTo({ top: window.scrollY + 500 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionItemModal]);

  return (
    <>
      <Notification />

      <Root sx={{ height: additionItemModal && "100%" }}>
        <CssBaseline />
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              overflow: "visible",
            },
          }}
        />

        <SwipeableDrawer
          anchor="bottom"
          open={additionItemModal}
          onClose={() => {
            fireUserInputEvent(sessionStorage.getItem("CurrentPage"), answer, "other_items", source);
            setInputVal(defaultVal);
            additionItemModalVar(false);
            summaryModalVar(true);
            source.match(/end of list pull/i) && window.scrollTo({ top: window.scrollY - 25 });
          }}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          ModalProps={{ keepMounted: false }}>
          <StyledBox
            sx={{
              position: "absolute",
              top: { xs: -105 },
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              visibility: "visible",
              right: 0,
              left: 0,
            }}>
            <Box sx={{ position: "absolute", p: 1.5 }}>
              <IconButton
                onClick={() => {
                  fireUserInputEvent(
                    sessionStorage.getItem("CurrentPage"),
                    answer,
                    "other_items",
                    source
                  );
                  setInputVal(defaultVal);
                  additionItemModalVar(false);
                  summaryModalVar(true);
                  source.match(/end of list pull/i) && window.scrollTo({ top: window.scrollY - 25 });
                }}
                size="small">
                <CloseIcon size="32" />
              </IconButton>
            </Box>
            <Puller />
            <Stack p={5} alignItems="center" gap={0.5} sx={{ textAlign: "center" }}>
              <Item className="tx600 tx110">May iba pang hinahanap?</Item>
              <Item className="tx400 tx80 c3" sx={{ maxWidth: 275 }}>
                Ilista rito at idadagdag sa order kung available
              </Item>
            </Stack>
          </StyledBox>
          <StyledBox
            sx={{
              p: 2,
              height: "100%",
              overflow: "auto",
              mb: source.match(/end of list pull/i) ? 3 : 2,
            }}>
            <Stack className="poppins" spacing={2.5} px={1}>
              <TextInput
                // autoFocus
                value={inputVal || ""}
                maxRows={5}
                onChange={(e) => {
                  setInputVal(e.target.value);
                  setAnswer(e.target.value);
                }}
                placeholder={`Quantity - Product \nQuantity - Product \nQuantity - Product \n`}
              />
              <Button
                className="tx100 tx600 hover_orange"
                variant="contained"
                size="large"
                sx={{
                  p: 1.7,
                  borderRadius: "12px",
                  textTransform: "unset",
                  // opacity: !inputVal > 0 && 0.5,
                }}
                onClick={() => {
                  // if (!inputVal) return;
                  onSave();
                  fireAnswerSurveyEvent(sessionStorage.getItem("CurrentPage"), answer, source);
                }}>
                {capitalize("Idagdag sa order list")}
              </Button>
            </Stack>
          </StyledBox>
        </SwipeableDrawer>
      </Root>
    </>
  );
}

const Root = styled("div")(() => ({
  width: "100%",
  top: 0,
  opacity: 0.35,
  position: "fixed",
}));

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  height: "100%",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default SwipeableDrawer2;
