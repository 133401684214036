import { Box, Grid, Stack } from "@mui/material";
// import next_button from "../../assets/images/next_button.svg";
import { useReactiveVar } from "@apollo/client";
import {
  additionItemModalVar,
  cartAdditionItemsVar,
  cartItemsVar,
  loadingLocalVar,
} from "../../graphql/reactiveVars";
import Item from "./Item";
import { AnimatedIconRight } from "../../assets/icon";

const NextButtonWithOtherItem = ({ label = "label", action }) => {
  const loading = useReactiveVar(loadingLocalVar);
  const cartAdditionItems = useReactiveVar(cartAdditionItemsVar);
  const additionItemModal = useReactiveVar(additionItemModalVar);
  const cartItems = useReactiveVar(cartItemsVar);

  if (loading || additionItemModal || !cartAdditionItems || cartItems?.length) return null;

  return (
    <Box>
      {/* <Grid container sx={{ height: 95 }} /> */}
      <Grid
        container
        alignItems="center"
        gap={{ xs: 0.5, sm: 1 }}
        className="pointer pointer_touch2"
        onClick={action}
        sx={{
          left: 0,
          bottom: 0,
          height: 72.5,
          position: "fixed",
          zIndex: "1251 !important",
          background: `#FCF4F3`,
          // backgroundImage: `url(${next_button})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          borderRadius: "12px 12px 0px 0px",
        }}>
        <Grid container sx={{ zIndex: -1 }} justifyContent="end">
          <AnimatedIconRight />
        </Grid>
        <Item flex={1} sx={{ position: "absolute", maxWidth: "50%" }}>
          <Stack px={5} spacing={0.5}>
            <Item className="tx600 tx75">Other items added</Item>
          </Stack>
        </Item>

        <Grid
          className="tx700 tx95 c5"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute", right: -5 }}>
          <Item
            sx={{
              textAlign: "center",
              width: "170px",
              height: "100%",
              letterSpacing: "0.75px",
              px: 1.75,
            }}>
            {label}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NextButtonWithOtherItem;
