import { Alert, Grid, IconButton } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { CheckIcon, CloseIcon } from "../../assets/icon";
import Item from "./Item";
import CancelIcon from "@mui/icons-material/Cancel";

export const notify = ({ message, severity = "error" }) => {
  toast.custom((t) => (
    <div
      style={{
        opacity: t.visible ? 1 : 0,
        transition: "opacity 100ms ease-in-out",
      }}>
      <Alert
        className="railway tx80 tx600 c2"
        icon={severity === "success" ? <CheckIcon /> : <CancelIcon sx={{ fontSize: 20 }} />}
        severity={severity}
        sx={{
          // borderRadius: "0.75rem",
          borderRadius: "12px",
          minWidth: "90vw",
          fontWeight: 500,
          bgcolor: severity === "success" && "#DFF2EE",
          // letterSpacing: "0.75px",
        }}
        elevation={1}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => toast.dismiss(t.id)}>
            <CloseIcon fill="#007358" />
          </IconButton>
        }>
        <Grid container alignContent="center" pt={0.25}>
          <Item>{message}</Item>
        </Grid>
      </Alert>
    </div>
  ));
};

export const Notification = () => {
  return <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 4000 }} />;
};

export default Notification;
