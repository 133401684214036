import { Box, styled, Drawer as MuiDrawer, Stack, Divider, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ListItemButton, List, ListItem } from "@mui/material";
import { snakeCase } from "lodash";
import { useEffect, useState } from "react";
import categories from "../../assets/data/categories.json";
import { activeCategoryVar, cartAdditionItemsVar } from "../../graphql/reactiveVars";
import { cartItemsVar, categoryVar } from "../../graphql/reactiveVars";
import { loadingLocalVar } from "../../graphql/reactiveVars";
import { useReactiveVar } from "@apollo/client";
import Notification from "./Notification";
import { useAnalytics, PageLabels } from "../../analytics";
import ProductPage from "../pages/ProductPage";
import Header from "./Header";
import ProgressBar from "./ProgressBar";
import Item from "./Item";
import metapixelEvents from "../../analytics/metapixelEvents";

const AppDrawer = () => {
  const { fireClickCategoryEvent } = useAnalytics({
    pageLabel: PageLabels.PRODUCT_LIST,
  });

  const [open] = useState(true);
  const [selected, setSelected] = useState(-1);
  const cartItems = useReactiveVar(cartItemsVar);
  const cartAdditionItems = useReactiveVar(cartAdditionItemsVar);
  const loading = useReactiveVar(loadingLocalVar);
  const activeCategory = useReactiveVar(activeCategoryVar);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 0; // Add 100px offset
    const options = categories.map(({ name }) => snakeCase(name));
    const elements = options.map((name) => document.getElementById(name));

    for (let i = elements.length - 1; i >= 0; i--) {
      if (elements[i] && scrollPosition >= elements[i].offsetTop) {
        setSelected(snakeCase(options[i]));
        categoryVar(snakeCase(options[i]));
        activeCategoryVar(options[i]);
        break;
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getLocalData();
    sessionStorage.removeItem("referenceNo");
    sessionStorage.removeItem("last_order_totalCost");
    window.addEventListener("scroll", handleScroll);
    metapixelEvents.metapixelViewContent();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {loading && (
        <Box
          className="bg_gray"
          sx={{
            width: "100vw",
            height: "100%",
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            backdropFilter: "blur(0.75px)",
            zIndex: 2000,
          }}>
          <ProgressBar loading={true} />
        </Box>
      )}

      <Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            position: "absolute",
            ml: "76px",
            width: {
              sm: "calc(100vw - 80px)",
            },
          }}>
          <ProductPage />
        </Box>
        {!cartItems?.length && <Header />}
        <Box sx={{ display: "flex", margin: "auto", minWidth: "100vw" }}>
          <CssBaseline />
          <Notification />
          <Drawer variant="permanent" open={open} classes={{ paper: "noscrollbar" }}>
            <Stack justifyContent="space-between" sx={{ height: "100%" }}>
              {!cartItems?.length && (
                <List>
                  <Box sx={{ height: 36 }} />
                </List>
              )}
              {/* <List> */}
              {categories.map((item) => (
                <ListItem key={item.id} disablePadding sx={{ display: "block" }} id={item.id}>
                  <ListItemButton
                    onClick={() => {
                      categoryVar(item.id);
                      activeCategoryVar(item.id);
                      fireClickCategoryEvent(item.name, PageLabels.PRODUCT_LIST);
                    }}
                    sx={{
                      justifyContent: "center",
                      height: "64px",
                      width: "72.5px",
                      borderLeft:
                        item.id === activeCategory ? "3.5px solid #ed5a29" : "3.5px solid transparent",
                    }}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      className={`${item.name === selected ? "capitalize selected" : " tx70 tx700 c4"}`}
                      sx={{
                        textWrap: "wrap",
                        whiteSpace: "normal",
                        width: "inherit",
                        textAlign: "center",
                        height: "inherit",
                      }}>
                      <Item
                        width="72.5px"
                        sx={{
                          textWrap: "wrap",
                          whiteSpace: "normal",
                        }}>
                        {item?.name?.match(/Ready-to-Drink/i) ? "Ready to Drink" : item.name}
                      </Item>
                    </Grid>
                  </ListItemButton>
                  <Divider />
                </ListItem>
              ))}
              {(!!cartItems?.length || !!cartAdditionItems?.length) && (
                <List>
                  <Box sx={{ height: 60 }} />
                </List>
              )}
              {/* </List> */}
            </Stack>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

const drawerWidth = 76;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  overflowY: "hidden",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
}));

export default AppDrawer;
