import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../atoms/Header";
import { useAnalytics, PageLabels } from "../../analytics";
import PageTimer from "../../analytics/PageTimer";

const TermsCondition = () => {
  const { fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.TERMS_CONDITION,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fireViewScreenEvent(PageLabels.TERMS_CONDITION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{ minHeight: "95vh", minWidth: "100vw" }}>
      <Header backAction={() => navigate("/confirm-order")} />
      <iframe
        src="https://business.growsari.com/terms-conditions/"
        style={{ marginTop: -35 }}
        title="Terms and Conditions"></iframe>
      <PageTimer />
    </Stack>
  );
};

export default TermsCondition;
