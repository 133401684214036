import React, { useEffect } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../atoms/Header";
import CartItem from "../organisms/CartItem";
import { useReactiveVar } from "@apollo/client";
import { cartAdditionItemsVar, cartItemsVar } from "../../graphql/reactiveVars";
import SwipeableDrawer from "../atoms/SwipeableDrawer";
import AddOtherItems from "../molecules/AddOtherItems";
import { updateSKUsOnCart } from "../../graphql/actions/uiActions";
import { useAnalytics, PageLabels } from "../../analytics";
import PageTimer from "../../analytics/PageTimer";
import Item from "../atoms/Item";
import TextInput from "../atoms/TextInput";
import NextButtonWithOtherItem from "../atoms/NextButtonWithOtherItem";
import { setAdditionalCartItems } from "../../graphql/actions/cartActions";
import metapixelEvents from "../../analytics/metapixelEvents";

const CartPage = () => {
  const { fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.MY_CART,
  });
  const navigate = useNavigate();
  const cartItems = useReactiveVar(cartItemsVar);
  const cartAdditionItems = useReactiveVar(cartAdditionItemsVar);

  useEffect(() => {
    fireViewScreenEvent(PageLabels.MY_CART);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    metapixelEvents.metapixelInitiateCheckout();
  }, []);

  return (
    <Stack className="bg_gray" sx={{ minHeight: "100vh", minWidth: "100vw" }}>
      <Header
        backAction={async () => {
          await updateSKUsOnCart();
          navigate("/products");
        }}
      />
      {!cartAdditionItems && <AddOtherItems source="MyCart" />}
      <Stack
        mt={1.25}
        gap={0.5}
        // sx={{ height: `calc(100vh - ${!cartAdditionItems ? "170px" : "150px"})`, overflowY: "auto" }}
        className="noscrollbar">
        {cartItems.map((x) => (
          <CartItem key={x.id} data={x} />
        ))}

        {cartAdditionItems && (
          <Stack sx={{ bgcolor: "#fff", mt: cartItems.length && 1.25 }}>
            <Stack px={2.25} spacing={0.25} py={2.5}>
              <Item className="tx700 tx95 ls100 c4a">🛍 Other items</Item>
              <Item className="tx400 tx100 ls25 lh150 c4">
                Ilista ang iba pang item na hinahanap niyo. Idadagdag namin sa order niyo kung available.{" "}
              </Item>
              <TextInput
                minRows={1}
                // maxRows={4}
                sx={{ pt: 0.75 }}
                value={cartAdditionItems || ""}
                onChange={(e) => setAdditionalCartItems(e.target.value)}
                placeholder={`Quantity - Product \nQuantity - Product \nQuantity - Product \n`}
              />
            </Stack>
          </Stack>
        )}
        <Stack py={1.25} px={2.5}>
          <Divider className="tx70 tx500 ls25" sx={{ color: "#A0A1AD", mb: 1 }}>
            End of list
          </Divider>
        </Stack>
      </Stack>

      <SwipeableDrawer />
      <PageTimer />
      {cartAdditionItems && !cartItems?.length && (
        <NextButtonWithOtherItem
          label="Pwede na ako tawagan"
          width="150px"
          action={(e) => {
            navigate(`/confirm-order`);
            // fireConfirmOrderEvent();
          }}
        />
      )}
      <Box sx={{ height: 100 }} />
    </Stack>
  );
};

export default CartPage;
