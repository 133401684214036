export const AnalyticsEventsTypes = {
  VIEW_SCREEN: "view_screen",
  CLICK_CATEGORY: "click_category",
  CHANGE_QUANTITY: "change_quantity",
  ANSWER_SURVEY: "answer_survey",
  CONFIRM_ORDER: "confirm_order",
  PLACE_ORDER: "place_order",
  FIELD_INPUT: "field_input",
  SCROLL: "scroll",
  CLICK_VIEW_PRODUCT_LIST: "click_view_product_list",
  CLICK_IM_INTERESTED: "click_im_interested",
  VIEW_POPUP: "view_popup",
  CLICK_ITEM_CARD: "click_item_card",
};

export const PageLabels = {
  WELCOME: "WelcomeScreen",
  HOW_TO_ORDER: "HowToOrderScreen",
  PRODUCT_LIST: "ProductList",
  MY_CART: "MyCart",
  CONFIRM_ORDER: "ConfirmOrder",
  ORDER_RECEIVED: "OrderReceived",
  TERMS_CONDITION: "TermsCondition",
};
