import { Box, Grid, Stack, TextField, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../atoms/Header";
import Item from "../atoms/Item";
import TextInput from "../atoms/TextInput";
import ConfirmOrderButton from "../atoms/ConfirmOrderButton";
import { cartAdditionItemsVar, totalVar } from "../../graphql/reactiveVars";
import { orderStatusLoadingVar } from "../../graphql/reactiveVars";
import { cartItemsVar, clientInfoVar } from "../../graphql/reactiveVars";
import SwipeableDrawer from "../atoms/SwipeableDrawer";
import { useReactiveVar } from "@apollo/client";
import { snakeCase } from "lodash";
import { sendOrders, setClientInfo } from "../../graphql/actions/cartActions";
import React, { useEffect, useState } from "react";
import ProgressBar from "../atoms/ProgressBar";
import { notify } from "../atoms/Notification";
import { clearData } from "../../graphql/actions/uiActions";
import { useAnalytics, PageLabels } from "../../analytics";
import PageTimer from "../../analytics/PageTimer";
import { themeInput } from "../../assets/theme";
import { formatMobileNumber } from "../atoms/helperFunc/helper";
import metapixelEvents from "../../analytics/metapixelEvents";

const ConfirmOrderPage = () => {
  const { firePlaceOrderEvent, fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.CONFIRM_ORDER,
  });
  const navigate = useNavigate();
  const cartItems = useReactiveVar(cartItemsVar);
  const clientInfo = useReactiveVar(clientInfoVar);
  const cartAdditionItems = useReactiveVar(cartAdditionItemsVar);
  const orderStatusLoading = useReactiveVar(orderStatusLoadingVar);
  const totalCost = useReactiveVar(totalVar);

  const [error, setError] = useState({});

  useEffect(() => {
    fireViewScreenEvent(PageLabels.CONFIRM_ORDER);
    metapixelEvents.metapixelAddPaymentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmOrder = () => {
    const errors = {};

    if (orderStatusLoading) return;
    // if (clientInfo?.mobile_number?.length !== 11) {
    // return console.log(!== "" || !clientInfo?.mobile_number);
    const inputMobile = clientInfo?.mobile_number || "";
    if (!inputMobile) {
      errors.mobile_number = "Please enter a mobile number";
      return setError(errors);
      // "Kailangan ng 11-digit mobile number";
    }

    if (inputMobile.length !== 11 || !inputMobile.match(/^09/)) {
      errors.mobile_number = "Sundan ang ganitong format: 091XXXXXXX ";
    }

    // if (clientInfo?.mobile_number?.length === 11 && !clientInfo?.mobile_number.match(/^09/)) {

    // if (clientInfo?.full_name?.length < 6) {
    //   errors.full_name = "Kailangan ng full name";
    // }
    // if (clientInfo?.complete_address?.length < 6) {
    //   errors.complete_address = "Kailangan ng complete address";
    // }

    const onSuccess = async () => {
      firePlaceOrderEvent(clientInfo.mobile_number, totalCost);
      await clearData();
      await orderStatusLoadingVar(false);
      await navigate("/order-status");
    };

    if (!cartItems?.length && !cartAdditionItems) {
      return notify({
        message: "Your cart is empty",
        severity: "error",
      });
    }

    if (!Object.values(errors)?.length) {
      setError(errors);
      orderStatusLoadingVar(true);
      sendOrders({ cartItems, clientInfo, cartAdditionItems, onSuccess, totalCost });
    } else {
      setError(errors);
    }
  };

  const onChange = ({ target }) => {
    if (target.name === "mobile_number") {
      const numbers = target?.value?.match(/\d+/g)?.join("");
      setClientInfo({ ...clientInfo, [target.name]: numbers });
      // setClientInfo({ ...clientInfo, [target.name]: target?.value });
    } else {
      setClientInfo({ ...clientInfo, [target.name]: target.value });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack sx={{ minHeight: "100vh", minWidth: "100vw" }}>
      <Header backAction={() => navigate("/cart")} />
      {orderStatusLoading && (
        <Box
          className="bg_gray"
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            backdropFilter: "blur(0.75px)",
            zIndex: 2000,
          }}>
          <ProgressBar loading={true} />
        </Box>
      )}

      <Stack px={2.25} spacing={0.25}>
        <Item className="tx700 tx125 ls100">Share contact details</Item>
        <Item className="ls25 lh150" sx={{ fontSize: "15px" }}>
          Kailangan lang ang iyong mga detalye para matawagan at ma-confirm ng aming ahente ang iyong
          order.
        </Item>
      </Stack>

      <Stack px={2.25} py={1.75} spacing={2}>
        <InputFieldNumber
          title="Mobile number"
          placeholder="Ex. 0912 345 6789"
          value={formatMobileNumber(clientInfo?.mobile_number || "")}
          // value={clientInfo?.mobile_number || ""}
          onChange={onChange}
          helperText={error.mobile_number}
        />
        <InputField
          title="Full Name"
          placeholder="Anna Cruz"
          value={clientInfo?.full_name || ""}
          onChange={onChange}
          helperText={error.full_name}
        />
        <InputField
          title="Complete address"
          placeholder="53-A Sunshine Street, Brgy. Ulan, Quezon City"
          rows={2}
          value={clientInfo?.complete_address || ""}
          onChange={onChange}
          helperText={error.complete_address}
        />
        <Box sx={{ height: 40 }} />
      </Stack>

      <Stack py={1.25} sx={{ bgcolor: "#fff", position: "fixed", bottom: 72 }}>
        <Grid className="tx75 c4" px={1.75}>
          <Item
            p={1}
            sx={{
              bgcolor: "#FCF4F3",
              borderRadius: "8px",
              textAlign: "center",
            }}>
            By confirming, you agree that Growsari can process your personal information.
            <b
              className="c1 pointer"
              onClick={() => navigate("/terms-conditions")}>{` Terms and Conditions >`}</b>
          </Item>
        </Grid>
      </Stack>

      <ConfirmOrderButton label="Ready na ako tawagan!" action={onConfirmOrder} />
      <SwipeableDrawer />
      <PageTimer />
    </Stack>
  );
};

const InputField = (props) => {
  const { title, placeholder, rows = 1, value, onChange, helperText, type } = props;

  const { fireUserInputEvent } = useAnalytics({
    pageLabel: sessionStorage.getItem("CurrentPage"),
  });

  return (
    <Stack>
      <Item className="tx600 tx80 ls100 c3">{title}</Item>
      <Item>
        <TextInput
          name={snakeCase(title)}
          onBlur={() =>
            fireUserInputEvent(sessionStorage.getItem("CurrentPage"), value, snakeCase(title))
          }
          type={type}
          placeholder={placeholder}
          maxRows={rows}
          sx={{ pt: 0.5 }}
          value={value}
          onChange={onChange}
          fullWidth
          helperText={helperText}
          error={!!helperText}
        />
      </Item>
    </Stack>
  );
};

const InputFieldNumber = (props) => {
  const { title, placeholder, rows = 1, value, onChange, helperText } = props;

  const { fireUserInputEvent } = useAnalytics({
    pageLabel: sessionStorage.getItem("CurrentPage"),
  });

  return (
    <Stack>
      <Item className="tx600 tx80 ls100 c3">{title}</Item>
      <Item>
        <ThemeProvider theme={themeInput}>
          <TextField
            // type="number"
            // type="phone"
            name={snakeCase(title)}
            onBlur={() =>
              fireUserInputEvent(sessionStorage.getItem("CurrentPage"), value, snakeCase(title))
            }
            placeholder={placeholder}
            maxRows={rows}
            sx={{ pt: 0.5, borderRadius: "20px !important" }}
            value={value}
            onChange={onChange}
            fullWidth
            helperText={helperText}
            error={!!helperText}
          />
        </ThemeProvider>
      </Item>
    </Stack>
  );
};

export default ConfirmOrderPage;
