import "./styles.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as FullStory from "@fullstory/browser";
import { ORG_ID } from "./config";
import { fullStoryRandomizer } from "./components/atoms/helperFunc/helper";


// run FullStory if deployed site & 10% chance
const runFullStory = fullStoryRandomizer()

if (runFullStory && !window.location.origin.match(/localhost/gi)) {
  FullStory.init({ orgId: ORG_ID }, ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`));
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
