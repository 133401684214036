import { TextField, ThemeProvider } from "@mui/material";
import React, { useRef } from "react";
import { themeInput } from "../../assets/theme";

const TextInput = (props) => {
  const inputRef = useRef(null);
  const { multiline = true, name, placeholder = "" } = props;

  // useEffect(() => {
  //   if (focusOnlastCharacter && inputRef.current) {
  //     inputRef.current.selectionStart = value?.length || 0;
  //     inputRef.current.selectionEnd = value?.length || 0;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <ThemeProvider theme={themeInput}>
      <TextField
        // maxRows={5}
        inputRef={inputRef}
        name={name}
        minRows={4}
        placeholder={placeholder}
        multiline={multiline}
        {...props}
      />
    </ThemeProvider>
  );
};

export default TextInput;
