import { Box, Grid, Stack } from "@mui/material";
import Item from "../atoms/Item";
import add_item_banner from "../../assets/images/add_item_banner.svg";
import { additionItemModalVar, summaryModalVar, sourceVar } from "../../graphql/reactiveVars";
import { useReactiveVar } from "@apollo/client";
import { useState } from "react";


const AddOtherItems = ({ source }) => {
  const additionItemModal = useReactiveVar(additionItemModalVar);
  const [touch, setTouch] = useState(false);

  const showModal = () => {
    summaryModalVar(additionItemModal);
    additionItemModalVar(!additionItemModal);
    sourceVar(source);
  };

  return (
    <Box sx={{ height: 70 }} mb={1.25} pt={1.75} px={2} className={`pointer ${touch && "pointer_touch"}`}>
      <Grid
        container
        onClick={showModal}
        onTouchStart={() => setTouch(true)}
        onTouchEnd={() => setTouch(false)}
        alignItems="center"
        gap={{ xs: 0.5, sm: 1 }}
        sx={{
          height: 62,
          background: `#FFF`,
          backgroundImage: `url(${add_item_banner})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "left",
          borderRadius: "8px",
          border: "1px solid silver",
        }}>
        <Item flex={1} pl="60px">
          <Stack px={1.5} pr={0} spacing={0.25} alignItems="start">
            <Item className={`tx600 tx85`} flex={1}>
              May iba pang hinahanap?
            </Item>
            <Item
              className="tx700 tx85"
              sx={{
                textAlign: "center",
                lineHeight: "16px",
                color: "#07A69C",
              }}>
              Add other items
            </Item>
          </Stack>
        </Item>
      </Grid>
    </Box>
  );
};

export default AddOtherItems;
