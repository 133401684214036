import React from "react";
import { Button, Grid, Typography } from "@mui/material";

export default function BlocksGame() {
  return (
    <Grid
      container
      style={{ overflow: "hidden", margin: "0" }}
      p={4}
      spacing={1}
    >
      <Grid item xs={12} className="underline" color="gray">
        Mini Games:
      </Grid>

      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Typography color="initial" fontSize="1.2rem">
              <span style={{ fontSize: "1rem", paddingRight: "5px" }}>1.)</span>
              Blocks
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="/mini-game/blocks"
              variant="contained"
              color="primary"
            >
              Go to URL
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Typography color="initial" fontSize="1.2rem">
              <span style={{ fontSize: "1rem", paddingRight: "5px" }}>2.)</span>
              Collect the coins
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="/mini-game/collect-the-coins"
              variant="contained"
              color="primary"
            >
              Go to URL
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* <iframe
        src="../mini-game/blocks/index.html"
        title="Embedded Game"
        scrolling="no"
        style={{ width: "100%", height: "100%", border: "none" }}
      /> */}
    </Grid>
  );
}
