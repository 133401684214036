import { makeVar } from "@apollo/client";
import theme from "../assets/theme";

// UI
export const runFullStoryVar = makeVar();
export const themeVar = makeVar(theme);
export const notificationVar = makeVar(false);
export const additionItemModalVar = makeVar(false);
// export const mobileNumberModalVar = makeVar(false);
export const sourceVar = makeVar("End of List Pull");
export const loadingLocalVar = makeVar(false);
export const isDarkModeVar = makeVar(
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
);
// Status & Client Info
export const clientInfoVar = makeVar({ full_name: "", mobile_number: "", complete_address: "" });
export const cartAdditionItemsVar = makeVar("");

// Cart
export const cartItemsVar = makeVar([]);
export const referenceNoVar = makeVar("");
export const totalVar = makeVar(0);
export const orderStatusLoadingVar = makeVar(false);
export const summaryModalVar = makeVar(false);

// Products
export const showNudgeVar = makeVar(true);
export const skuListVar = makeVar([]);
export const categoryVar = makeVar("7c77445a-e619-489d-b606-5dc8ad3f13a0");
export const activeCategoryVar = makeVar("7c77445a-e619-489d-b606-5dc8ad3f13a0");
