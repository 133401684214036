import { setForage } from "../forage";
import { cartAdditionItemsVar, cartItemsVar, clientInfoVar } from "../reactiveVars";
import { orderStatusLoadingVar, referenceNoVar, totalVar } from "../reactiveVars";
import { checkError } from "./uiActions";
import { notify } from "../../components/atoms/Notification";
import { format } from "date-fns";
import { GSHEET_SRIPT, GSHEET_SRIPT_BACKUP } from "../../config";

export const setCartItems = async (data = []) => {
  try {
    cartItemsVar(data);
    setForage("cartItems", data);
  } catch (e) {
    checkError(e);
  }
};

export const setAdditionalCartItems = async (data = "") => {
  try {
    cartAdditionItemsVar(data);
    setForage("additionCartItems", data);
  } catch (error) {}
};

export const setClientInfo = async (data = {}) => {
  try {
    clientInfoVar(data);
    setForage("clientInfoItems", data);
  } catch (error) {}
};

export const setTotalCost = async (cost) => {
  try {
    totalVar(cost);
    setForage("totalCost", cost);
  } catch (error) {}
};

export const sendOrders = async ({ cartItems, clientInfo, cartAdditionItems, onSuccess, totalCost }) => {
  const timestamp = Date.now();

  const serializeData = cartItems.map(({ id, display_name, qty, price }, index) => ({
    order_id: index !== 0 ? "" : `${clientInfo.mobile_number.slice(-4)}-${timestamp}`,
    created_at: index !== 0 ? "" : format(timestamp, "yyyy-MM-dd hh:mmaaa"),
    full_name: index !== 0 ? "" : clientInfo.full_name,
    address: index !== 0 ? "" : clientInfo.complete_address,
    mobile_no: index !== 0 ? "" : "" + clientInfo.mobile_number,
    other_items: index !== 0 ? "" : cartAdditionItems,
    sku_id: id,
    display_name,
    qty,
    price,
    total: (qty * price).toFixed(2),
  }));

  const payload = new FormData();
  const payloadSerialize = JSON.stringify(
    serializeData?.length
      ? serializeData
      : [
          {
            order_id: `${clientInfo.mobile_number.slice(-4)}-${timestamp}`,
            created_at: format(timestamp, "yyyy-MM-dd hh:mmaaa"),
            full_name: clientInfo.full_name,
            address: clientInfo.complete_address,
            mobile_no: "" + clientInfo.mobile_number,
            other_items: cartAdditionItems,
            sku_id: "",
            display_name: "",
            qty: "",
            price: "",
            total: "To be confirmed",
          },
        ]
  );
  payload.append("data", payloadSerialize);

  referenceNoVar("");

  // masterfile;
  await fetch(GSHEET_SRIPT, { method: "POST", body: payload })
    .then((response) => response.text())
    .then(() => {
      referenceNoVar(`${clientInfo.mobile_number.slice(-4)}-${timestamp}`);
      sessionStorage.setItem("referenceNo", `${clientInfo.mobile_number.slice(-4)}-${timestamp}`);
    })
    .then(() => {
      sessionStorage.setItem("last_order_totalCost", totalCost);
      onSuccess();
    })
    .catch((error) => {
      notify({ message: "Error sending orders, Please try again.", severity: "error" });
      console.error("Error:", error);
      orderStatusLoadingVar(false);
    });

  // backup
  await fetch(GSHEET_SRIPT_BACKUP, { method: "POST", body: payload })
    .then((response) => response.text())
    .catch((error) => {
      console.error("Error:", error);
      notify({ message: "Error sending orders, Please try again.", severity: "error" });
    });
};
