import { Box, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { BackIcon, RebateIcon } from "../../assets/icon";
import Item from "./Item";
import { useLocation } from "react-router-dom";
import box_products from "../../assets/images/box_products.svg";
import arrow_down from "../../assets/images/arrow_down.svg";
import { useReactiveVar } from "@apollo/client";
import { loadingLocalVar } from "../../graphql/reactiveVars";
import { useWidth } from "./helperFunc/useWidth";

const Header = ({ backAction }) => {
  const { pathname } = useLocation();
  const loading = useReactiveVar(loadingLocalVar);

  const { width } = useWidth();

  return (
    <>
      <Grid
        container
        px={1.5}
        gap={2.5}
        alignItems="center"
        sx={{
          height: 56,
          minWidth: "100vw",
          bgcolor: "#FFF",
          position: "fixed",
          zIndex: 1201,
          backdropFilter: loading && "blur(0.75px)",
          // filter: `drop-shadow(1px 1px 4px red)`
          boxShadow: pathname === "/products" && `0px 8px 16px 0px rgba(38, 35, 56, 0.10)`,
        }}>
        {pathname === "/products" ? (
          <Grid container ml={width < 360 && -1}>
            <Item sx={{ position: "absolute", left: 5, top: -4 }}>
              <img src={box_products} alt="gs_logo" />
            </Item>
            <Item sx={{ position: "absolute", right: 15, top: -25 }}>
              <img src={arrow_down} alt="gs_logo" />
            </Item>

            <Stack spacing={{ xs: 0.25, sm: 0.75, md: 1 }} ml={10} width="100%">
              <Item
                className="tx700 ls75"
                sx={{ maxWidth: "60%", lineHeight: 1.2, fontSize: { xs: "12.75px", md: "14.5px" } }}>
                Pumili ng paninda at ilagay ang quantity!
              </Item>

              <Item
                sx={{ ml: 10, lineHeight: 1, fontSize: { xs: "11.5px", md: "12.5px" }, zIndex: 2 }}
                className="c1 tx600">
                <Grid container sx={{ flexWrap: "nowrap" }} alignItems="center">
                  <Item sx={{ minWidth: "38px" }}>Up to</Item>
                  <Item sx={{ minWidth: "62px" }}>
                    <RebateIcon />
                  </Item>
                  <Item sx={{ minWidth: "260px" }}>rebate sa unang order!</Item>
                </Grid>
              </Item>
            </Stack>
          </Grid>
        ) : (
          <Item>
            <IconButton size="small" onClick={backAction}>
              <BackIcon />
            </IconButton>
          </Item>
        )}
        {pathname === "/cart" && (
          <Item className="tx700 tx100 c4a" sx={{ letterSpacing: "0.75px" }}>
            Order List
          </Item>
        )}
      </Grid>
      <Box sx={{ height: 55 }} />
    </>
  );
};

export default Header;
