import { checkError } from "./uiActions";

export const getProductsByCategory = (category, skuList = []) => {
  try {
    // return skuList?.filter((x) => x.tags?.includes(category));
    return skuList?.filter((x) => x.category_id === category);
  } catch (e) {
    checkError(e);
  }
};
