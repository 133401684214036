import { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { additionItemModalVar, cartItemsVar, loadingLocalVar } from "../../graphql/reactiveVars";
import { summaryModalVar, totalVar } from "../../graphql/reactiveVars";
import { sumBy } from "lodash";
import Item from "./Item";
import numeral from "numeral";
import { setTotalCost } from "../../graphql/actions/cartActions";
import { AnimatedIconRight } from "../../assets/icon";

const NextButton = ({ label = "label", action, width = "160px" }) => {
  const cartItems = useReactiveVar(cartItemsVar);
  const [totalItems, setTotalItems] = useState(0);
  const summaryModal = useReactiveVar(summaryModalVar);
  const totalCost = useReactiveVar(totalVar);
  const additionItemModal = useReactiveVar(additionItemModalVar);
  const loading = useReactiveVar(loadingLocalVar);

  const getTotal = (cart, field = "qty") => sumBy(cart, (item) => +item[field] * +item.price).toFixed(2);

  useEffect(() => {
    let total = getTotal(cartItems);
    setTotalCost(total);
    setTotalItems(cartItems.reduce((accumulator, obj) => accumulator + obj.qty, 0));
    summaryModalVar(total > 0);
  }, [cartItems]);

  if (loading || additionItemModal || !totalItems) return null;

  return (
    <Box className={summaryModal ? "fade.visible" : "fade"}>
      {/* <Grid container sx={{ height: 95 }} /> */}
      <Grid
        container
        alignItems="center"
        gap={{ xs: 0.5, sm: 1 }}
        className="pointer pointer_touch2"
        onClick={action}
        sx={{
          left: 0,
          bottom: 0,
          height: 72.5,
          position: "fixed",
          zIndex: "1251 !important",
          // background: `#FCF4F3`,
          // backgroundImage: `url(${next_button})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          borderRadius: "12px 12px 0px 0px",
        }}>
        <Grid container sx={{ zIndex: -1 }} justifyContent="end">
          <AnimatedIconRight />
        </Grid>
        <Item flex={1} sx={{ position: "absolute", mb: -0.25 }}>
          <Stack px={4} spacing={0.5}>
            <Item className="tx600 tx70" sx={{ zIndex: "12522 !important" }}>{`${totalItems} item${
              totalItems > 1 ? "s" : ""
            } added`}</Item>
            <Item className="c1 tx700 tx100">₱ {numeral(totalCost).format("0,0.00")}</Item>
          </Stack>
        </Item>
        <Grid
          className="tx700 tx95 c5"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute", right: 0 }}>
          <Item
            sx={{
              textAlign: "center",
              width: width,
              height: "100%",
              letterSpacing: "0.75px",
              px: 1.75,
            }}>
            {label}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NextButton;
