import { Box, Button, Grid, Stack } from "@mui/material";
import MobileSize from "../atoms/MobileSize";
import white_gs_logo from "../../assets/images/white_gs_logo.png";
import in_store_payments from "../../assets/images/in_store_payments.svg";
import truck_delivery from "../../assets/images/truck_delivery.svg";
import being_packed from "../../assets/images/being_packed.svg";

import Item from "../atoms/Item";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { PageLabels, useAnalytics } from "../../analytics";
import { useEffect } from "react";
import { upperCase } from "lodash";
import { useWidth } from "../atoms/helperFunc/useWidth";

const HowToOrder = () => {
  const navigate = useNavigate();
  const { width } = useWidth();
  const { fireViewScreenEvent, fireClickButtonEvent } = useAnalytics({
    pageLabel: PageLabels.HOW_TO_ORDER,
  });

  useEffect(() => {
    fireViewScreenEvent(PageLabels.HOW_TO_ORDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickNext = async (event_source) => {
    navigate("/products");
    await fireClickButtonEvent(PageLabels.HOW_TO_ORDER, event_source);
  };

  return (
    <MobileSize bgColor="#00B7A8">
      <Stack alignItems="center" pt={4}>
        <img src={white_gs_logo} alt="gs_logo" width={200} />
        <Item className="tx700 tx125 ls100" sx={{ fontStyle: "italic", color: "#FFEB92" }}>
          = Easy Order =
        </Item>
      </Stack>

      <Box onClick={() => onClickNext("HowToOrder Banner")}>
        <Stack alignItems="center" mt={3} className="tx300 tx80 ls25">
          <Stack
            spacing={0.5}
            sx={{ py: 1.5, zIndex: 1, width: 270, textAlign: "center" }}
            className="c5">
            <Item className="tx700 tx150">Welcome, ka-Sari!</Item>
            <Item className="fs15">Mag-order na mula sa</Item>
            <Item className="fs15">Growsari Easy Order website!</Item>
          </Stack>

          <Item sx={{ position: "absolute" }}>
            <svg
              width="284"
              height="267"
              viewBox="0 0 284 267"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="284" height="267" rx="24" fill="#008577" fillOpacity="0.8" />
            </svg>
          </Item>
          <Item sx={{ zIndex: 1 }}>
            <svg
              width="304"
              height="187"
              viewBox="0 0 304 187"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M280 0H24C10.7452 0 0 10.7452 0 24V163C0 176.255 10.7452 187 24 187H280C293.255 187 304 176.255 304 163V24C304 10.7452 293.255 0 280 0Z"
                fill="white"
              />
            </svg>
          </Item>

          <Item
            sx={{
              pt: 13,
              position: "absolute",
              width: "275px",
              mt: 3,
              zIndex: 1,
              color: "#000",
              px: 0.5,
            }}>
            <Stack spacing={1.25} className="fs15 tx400 c4a">
              <Grid container gap={1.5}>
                <Number value={1} />
                <Item flex={1}>Pumili ng paninda at i-submit ang iyong mobile number</Item>
              </Grid>

              <Grid container gap={1.5}>
                <Number value={2} />
                <Item flex={1}>
                  Tatawagan ka ng aming ahente sa loob ng 2 hours para i-confirm ang order
                </Item>
              </Grid>
            </Stack>
            <Grid container justifyContent="center" className="c3 tx500" mt={0.75}>
              <Item flex={1} sx={{ textAlign: "center", fontSize: "10px" }}>
                Prices may vary depending on location. Our agent will confirm via call.
              </Item>
            </Grid>
          </Item>
        </Stack>

        <Grid container justifyContent="center" gap={width < 360 ? 0.75 : 1.5} pt={2}>
          <Promotion img={being_packed} title="Bagsak presyo" />
          <Promotion img={truck_delivery} title="Free delivery" />
          <Promotion img={in_store_payments} title="Cash on delivery" />
        </Grid>

        <Stack
          className="tx600 c5"
          sx={{
            px: 4,
            pt: 1.75,
            textShadow: "5px 5px 6px #059083",
            textAlign: "center",
            height: 200,
            fontSize: "23px",
          }}>
          Kaya love kami ng 150,000+ tinderas! 🤍
        </Stack>
      </Box>
      <Stack
        sx={{
          zIndex: 1,
          position: "fixed",
          bottom: 0,
          maxWidth: "425px",
          width: "100%",
          pb: 2,
          bgcolor: "#fff",
        }}>
        <Item p={2}>
          <Button
            fullWidth
            className="tx600"
            variant="contained"
            size="large"
            sx={{ p: 1.7, borderRadius: "12px", textTransform: "unset", fontSize: "15px" }}
            onClick={() => onClickNext("Anong paninda meron?")}
            endIcon={<ArrowForwardIcon size="small" />}>
            Anong paninda meron?
          </Button>
        </Item>
      </Stack>
    </MobileSize>
  );
};

const Number = ({ value = "" }) => (
  <Grid
    container
    pt="2px"
    justifyContent="center"
    alignItems="center"
    className="tx600 tx85"
    sx={{ bgcolor: "#00B7A8", color: "#fff", width: 24, height: 24, borderRadius: "50%" }}>
    <Item>{value}</Item>
  </Grid>
);

const Promotion = ({ img, title, subtitle }) => (
  <Item
    p={1}
    pb={1.5}
    sx={{
      width: "100px",
      borderRadius: 4,
      zIndex: 1,
      bgcolor: "#fff",
      textAlign: "center",
    }}>
    <Item sx={{ height: "70px" }}>
      <img
        src={img}
        alt=""
        style={{
          width: "100%",
          height: "70px",
          borderRadius: "6px",
        }}
      />
    </Item>
    <Item className="tx700 tx117 ls75 c2b" sx={{ lineHeight: "17px" }}>
      {upperCase(title)}
    </Item>
  </Item>
);

export default HowToOrder;
