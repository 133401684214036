import { useCallback, useEffect } from "react";
import { AnalyticsEventsTypes } from ".";
import * as FullStory from "@fullstory/browser";
import { runFullStoryVar } from "../graphql/reactiveVars";
import { useReactiveVar } from "@apollo/client";

const getDefaultParameters = (pageLabel) => ({
  timestamp: Date.now().toString(),
  screen_name: pageLabel,
  utm_source: sessionStorage.getItem("utm"),
});

const useAnalytics = ({ firePageEventsOnLoad = true, pageLabel }) => {
  const runFullStory = useReactiveVar(runFullStoryVar)
  
  const fireEvent = useCallback(
    async (eventType, eventParams = {}, fireCount = 0) => {
      const defaultParams = getDefaultParameters(pageLabel);
      const params = {
        ...defaultParams,
        ...eventParams,
      };
      if (window.location.origin.match(/localhost/gi)) return;
      if (window?.gtag) {
        window.gtag("event", eventType, params);
        runFullStory && FullStory.event(eventType, params);
      } else if (fireCount < 5) {
        // Retry firing after a short delay
        setTimeout(() => {
          fireEvent(eventType, eventParams, fireCount + 1);
        }, 250);
      } else {
        console.warn("Unable to fire event after multiple retries.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageLabel]
  );

  const firePageViewEvent = useCallback(() => {
    const prevPage = sessionStorage.getItem("CurrentPage");

    if (pageLabel !== prevPage) {
      sessionStorage.setItem("CurrentPage", pageLabel);
      sessionStorage.setItem("PreviousPage", prevPage);

      fireEvent("page_view", {
        page_title: pageLabel,
        page_path: window.location.pathname + window.location.search,
      });
    }
  }, [fireEvent, pageLabel]);

  const fireViewScreenEvent = useCallback(
    (current_screen_name) => {
      const parameters = {
        current_screen_name,
        prev_screen_name: sessionStorage.getItem("PreviousPage"),
        time_spent: sessionStorage.getItem("TimeSpent"), // Time spent on the previous page
        // screen_state: sessionStorage.getItem("ScreenState"),
        user_uuid: localStorage.getItem("UserUUID"),
      };

      fireEvent(AnalyticsEventsTypes.VIEW_SCREEN, parameters);
    },
    [fireEvent]
  );

  const fireClickCategoryEvent = useCallback(
    (category_name, screen_name) => {
      const parameters = {
        category_name,
        screen_name,
        // screen_state: sessionStorage.getItem("ScreenState"),
        user_uuid: localStorage.getItem("UserUUID"),
      };

      fireEvent(AnalyticsEventsTypes.CLICK_CATEGORY, parameters);
    },
    [fireEvent]
  );

  const fireChangeQuantityEvent = useCallback(
    (product_name, quantity, screen_name) => {
      const parameters = {
        product_name,
        quantity,
        screen_name,
        user_uuid: localStorage.getItem("UserUUID"),
      };

      fireEvent(AnalyticsEventsTypes.CHANGE_QUANTITY, parameters);
    },
    [fireEvent]
  );

  const fireAnswerSurveyEvent = useCallback(
    (screen_name, answer, event_source) => {
      const parameters = {
        screen_name,
        answer,
        event_source,
        user_uuid: localStorage.getItem("UserUUID"),
      };

      fireEvent(AnalyticsEventsTypes.ANSWER_SURVEY, parameters);
    },
    [fireEvent]
  );

  const fireScrollScreenEvent = useCallback(
    (screen_name) => {
      const parameters = {
        screen_name,
        // screen_state: sessionStorage.getItem("ScreenState"),
        user_uuid: localStorage.getItem("UserUUID"),
      };

      fireEvent(AnalyticsEventsTypes.SCROLL, parameters);
    },
    [fireEvent]
  );

  const fireConfirmOrderEvent = useCallback(() => {
    const parameters = {
      user_uuid: localStorage.getItem("UserUUID"),
    };

    fireEvent(AnalyticsEventsTypes.CONFIRM_ORDER, parameters);
  }, [fireEvent]);

  const firePlaceOrderEvent = useCallback(
    (mobile_number, total_cost) => {
      const parameters = {
        mobile_number,
        user_uuid: localStorage.getItem("UserUUID"),
        total_cost: total_cost,
      };

      fireEvent(AnalyticsEventsTypes.PLACE_ORDER, parameters);
    },
    [fireEvent]
  );

  const fireUserInputEvent = useCallback(
    (screen_name, description, field_name, event_source) => {
      const parameters = {
        screen_name,
        description,
        field_name,
        event_source,
        // screen_state: sessionStorage.getItem("ScreenState"),
        user_uuid: localStorage.getItem("UserUUID"),
      };
      fireEvent(AnalyticsEventsTypes.FIELD_INPUT, parameters);
    },
    [fireEvent]
  );

  const fireClickButtonEvent = useCallback(
    (current_screen_name, event_source) => {
      const parameters = {
        current_screen_name,
        user_uuid: localStorage.getItem("UserUUID"),
        event_source,
      };
      fireEvent(AnalyticsEventsTypes.CLICK_VIEW_PRODUCT_LIST, parameters);
    },
    [fireEvent]
  );

  const fireClickItemCard = useCallback(
    (product_name, current_screen_name) => {
      const parameters = {
        current_screen_name,
        user_uuid: localStorage.getItem("UserUUID"),
        product_name,
      };
      fireEvent(AnalyticsEventsTypes.CLICK_ITEM_CARD, parameters);
    },
    [fireEvent]
  );

  const fireViewPopup = useCallback(
    (popup_name, current_screen_name) => {
      const parameters = {
        current_screen_name,
        user_uuid: localStorage.getItem("UserUUID"),
        popup_name,
      };
      fireEvent(AnalyticsEventsTypes.VIEW_POPUP, parameters);
    },
    [fireEvent]
  );

  useEffect(() => {
    if (firePageEventsOnLoad) {
      firePageViewEvent();
    }
  }, [firePageEventsOnLoad, pageLabel, firePageViewEvent]);

  return {
    fireEvent,
    firePageViewEvent,
    fireViewScreenEvent,
    fireClickCategoryEvent,
    fireChangeQuantityEvent,
    fireAnswerSurveyEvent,
    fireScrollScreenEvent,
    fireConfirmOrderEvent,
    firePlaceOrderEvent,
    fireUserInputEvent,
    fireClickButtonEvent,
    fireClickItemCard,
    fireViewPopup,
    pageLabel,
  };
};

export default useAnalytics;
