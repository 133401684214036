import { Box, ClickAwayListener, Grid, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { useState } from "react";
import Item from "../atoms/Item";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useReactiveVar } from "@apollo/client";
import { cartItemsVar, showNudgeVar } from "../../graphql/reactiveVars";
import { setCartItems } from "../../graphql/actions/cartActions";
import { notify } from "../atoms/Notification";
// import { getItemName } from "../atoms/helperFunc/helper";
import { useAnalytics, PageLabels } from "../../analytics";
import ProductImage from "../atoms/ProductImage";
import { themeNudge } from "../../assets/theme";

const ProductCard = ({ data = {} }) => {
  const { fireChangeQuantityEvent, fireClickItemCard, fireViewPopup } = useAnalytics({
    pageLabel: PageLabels.PRODUCT_LIST,
  });
  const [nudge, setNudge] = useState(false);
  const [count, setCount] = useState(data?.qty || 0);
  const cartItems = useReactiveVar(cartItemsVar);
  const showNudge = useReactiveVar(showNudgeVar);

  const addCartItem = (data) => {
    const updatedArr = [...cartItems];
    const existingItemIndex = cartItems.findIndex((item) => item.id === data.id);

    if (updatedArr[existingItemIndex]?.qty >= 50) {
      return notify({
        message: "Maximum of 50 items are allowed.",
        severity: "warning",
      });
    }

    if (existingItemIndex !== -1) {
      updatedArr[existingItemIndex].qty += 1;
    } else {
      updatedArr.push({ ...data, qty: 1 });
    }

    setCount(count + 1);
    setCartItems(updatedArr); // localforage + G.Analytics
  };

  const removeCartItem = (itemId) => {
    const updatedArr = [...cartItems];
    const existingItemIndex = cartItems.findIndex((existingItem) => existingItem.id === itemId);

    if (count <= 0) return;

    if (updatedArr[existingItemIndex].qty <= 1) {
      updatedArr.splice(existingItemIndex, 1); // Remove item from array
    } else {
      updatedArr[existingItemIndex].qty -= 1;
    }

    setCount(count - 1);
    setCartItems(updatedArr);
  };

  const handleNudge = () => {
    if (!showNudge) {
      setNudge(false);
      return showNudgeVar(false);
    }
    setNudge(true);
    showNudgeVar(false);
    fireClickItemCard(data.display_name, PageLabels.PRODUCT_LIST);
    fireViewPopup("quantity_nudge", PageLabels.PRODUCT_LIST);
    setTimeout(() => {
      setNudge(false);
    }, 5000);
  };

  return (
    <Item key={data.id} sx={{ bgcolor: "#fff", minHeight: "60px" }} className="tx85" px={1.5} py={1.75}>
      <ClickAwayListener onClickAway={() => setNudge(false)}>
        <div>
          <Grid container spacing={1.5} onClick={handleNudge}>
            <Item>
              <ProductImage data={data} />
            </Item>
            <Item flex={1} className="tx600 tx80">
              {data.display_name}
              {/* {getItemName(data.display_name)} */}
            </Item>
          </Grid>

          <Grid container spacing={1.5} justifyContent="space-between">
            <Item mt={0.75} flex={1}>
              <Grid container spacing={0.75} alignItems="center" onClick={handleNudge}>
                <Item className="c1 tx700 tx95">₱{data.price?.toFixed(2)}</Item>
                <Item className="tx600 tx70 c3">per {data.size}</Item>
              </Grid>
            </Item>
            <Item className="c1 tx700 tx95">
              <Grid container alignContent="center">
                <Item
                  p={0.75}
                  onClick={async () => {
                    await removeCartItem(data.id);
                    fireChangeQuantityEvent(data.display_name, count - 1, PageLabels.PRODUCT_LIST);
                  }}>
                  <IconButton
                    size="small"
                    className={count > 0 ? "hover_gray" : "disabled"}
                    disabled={count <= 0}
                    sx={{ bgcolor: "#DCF2F1" }}>
                    <RemoveIcon sx={{ height: 15, width: 15 }} />
                  </IconButton>
                </Item>
                <Item
                  className="c4"
                  sx={{
                    width: "25px",
                    textAlign: "center",
                    margin: "auto",
                  }}>
                  {count}
                </Item>

                <Item
                  p={0.75}
                  onClick={async () => {
                    if (nudge) return setNudge(false);
                    await addCartItem(data);
                    fireChangeQuantityEvent(data.display_name, count + 1, PageLabels.PRODUCT_LIST);
                  }}>
                  <ThemeProvider theme={themeNudge}>
                    <Tooltip
                      open={nudge}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={<Box>Pindutin ito para maka-add ng items</Box>}
                      arrow
                      placement="bottom-end">
                      <IconButton
                        size="small"
                        variat="contained"
                        className={count < 50 ? "hover_green red" : "disabled"}
                        disabled={count >= 50}
                        sx={{ bgcolor: "#07A69C", color: "#fff" }}>
                        <AddIcon sx={{ height: 15, width: 15 }} />
                      </IconButton>
                    </Tooltip>
                  </ThemeProvider>
                </Item>
              </Grid>
            </Item>
          </Grid>
        </div>
      </ClickAwayListener>
    </Item>
  );
};

export default ProductCard;
