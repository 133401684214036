import { Stack, Dialog } from "@mui/material";

export default function ModalBox({ modal, setModal, children, sx }) {
  return (
    <Dialog
      open={modal}
      onClose={() => setModal(false)}
      sx={{
        width: "100%",
        "& .MuiDialog-paper": { borderRadius: "12px" },
      }}>
      <Stack
        sx={{
          minHeight: 175,
          width: 310,
          ...sx,
        }}
        gap={1.25}
        px={3}
        py={1}>
        {children}
      </Stack>
    </Dialog>
  );
}
