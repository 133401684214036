import { Chip, Divider, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import Item from "../atoms/Item";
import { useReactiveVar } from "@apollo/client";
import { referenceNoVar, totalVar } from "../../graphql/reactiveVars";
import { useAnalytics, PageLabels } from "../../analytics";
import PageTimer from "../../analytics/PageTimer";
import numeral from "numeral";
import metapixelEvents from "../../analytics/metapixelEvents";

const OrderStatusPage = () => {
  const navigate = useNavigate();
  const totalCost = useReactiveVar(totalVar);
  const referenceNo = useReactiveVar(referenceNoVar);
  // const isDarkMode = useReactiveVar(isDarkModeVar);
  const orderRef = sessionStorage.getItem("referenceNo");
  const last_order_totalCost = sessionStorage.getItem("last_order_totalCost");
  const { fireViewScreenEvent } = useAnalytics({
    pageLabel: PageLabels.ORDER_RECEIVED,
  });

  useEffect(() => {
    if (!orderRef) return navigate("/confirm-order");
    referenceNoVar(orderRef);
    fireViewScreenEvent(PageLabels.ORDER_RECEIVED);
    metapixelEvents.metapixelPurchase(totalCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack sx={{ minWidth: "100vw" }} justifyContent="center" alignItems="center" spacing={0.5}>
        <Grid container justifyContent="center">
          <Item sx={{ height: 112, width: "100%", bgcolor: "#DFF2EE" }}></Item>
          <Item mt={-9}>
            <svg
              width="196"
              height="124"
              viewBox="0 0 196 124"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1846_8552)">
                <path
                  d="M97.7693 72.4361L164 20.9711H123.358L97.7693 72.4361ZM97.7693 72.4361L121.746 123.901H160.631L97.7693 72.4361ZM195.503 51.3985L97.7334 72.4361L195.503 96.0541V51.3985ZM97.7693 72.4361L31.5385 123.901H72.1801L97.7693 72.4361ZM34.8715 20.9711L97.7693 72.4361L73.7929 20.9711H34.8715ZM97.7693 72.4361L0 48.8181V93.4378L97.7693 72.4361Z"
                  fill="#4CB896"
                />
                <path
                  d="M97.7691 72.4361L123.358 20.9711H73.7927L97.7691 72.4361ZM146.546 61.9353L195.538 51.3985V37.0628C195.538 28.1747 188.335 20.9711 179.447 20.9711H164L97.7691 72.4361L146.009 62.0786C144.826 69.3181 141.744 76.1634 137.264 82.0052L97.7691 72.4719L123.896 93.8321C119.559 96.4125 115.008 98.2761 110.349 99.423L97.7691 72.4361L84.5086 99.1363C79.7062 97.8461 75.1187 95.8749 70.9255 93.3303L97.7691 72.4719L57.5217 81.1092C53.2927 75.3391 50.3897 68.4938 49.3503 60.7525L97.7691 72.4361L34.8713 20.9711H16.1274C7.23933 20.9711 0.0356445 28.1747 0.0356445 37.0628V48.8181L48.9919 60.645V61.7202C48.9561 68.9239 50.7481 75.7691 53.9378 81.8618L0.0356445 93.4378V107.774C0.0356445 116.662 7.23933 123.865 16.1274 123.865H31.5741L66.4098 96.8068C70.8897 100.355 76.0147 103.114 81.6056 104.978L72.2158 123.865H121.781L113.108 105.265C118.592 103.544 123.788 100.928 128.376 97.4518L160.667 123.865H179.411C188.299 123.865 195.503 116.662 195.503 107.774V96.0182L141.063 82.8653C143.213 78.9588 144.826 74.7657 145.686 70.3575C146.295 67.8129 146.51 64.8741 146.546 61.9353Z"
                  fill="#007358"
                />
                <path
                  d="M97.769 9.53845C124.72 9.53845 146.582 30.0743 146.582 55.3767C146.582 80.6792 124.72 101.251 97.769 101.251C70.818 101.251 48.9561 80.715 48.9561 55.3767C48.9561 30.0384 70.818 9.53845 97.769 9.53845Z"
                  fill="white"
                />
                <path
                  d="M92.7518 84.4064C85.8707 80.3924 78.2728 75.6616 69.1338 69.8557C66.3742 68.0995 63.8296 65.4833 64.4747 61.7561C65.1198 58.0288 68.417 56.8102 71.7142 58.7814C79.2046 63.333 85.7631 67.347 91.6408 70.895C100.17 59.6416 108.7 50.0008 120.921 42.9405C123.932 41.1844 128.914 41.8295 131.386 43.9798C133.859 46.1302 132.82 49.0332 129.738 50.7535C114.255 59.4265 104.65 70.1424 92.7518 84.4064Z"
                  fill="#007358"
                />
                <path
                  d="M91.9632 79.6757C85.0821 75.6617 77.4842 70.9309 68.3452 65.1249C65.5856 63.3688 63.041 60.7526 63.6861 57.0254C64.3312 53.2981 67.6285 52.0795 70.9257 54.0507C78.4161 58.6023 84.9746 62.6163 90.8522 66.1643C99.382 54.9108 107.912 45.2701 120.133 38.2098C123.143 36.4537 128.125 37.0987 130.598 39.2491C133.071 41.3994 132.031 44.3024 128.949 46.0227C113.431 54.6957 103.826 65.4117 91.9632 79.6757Z"
                  fill="#4CB896"
                />
              </g>
              <defs>
                <clipPath id="clip0_1846_8552">
                  <rect width="195.538" height="124" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Item>
        </Grid>

        <Item className="tx700 tx125 c2" pt={2}>
          Antayin ang aming tawag!
        </Item>
        <Item
          px={5}
          py={1.5}
          textAlign="center"
          className="tx300 ls75"
          sx={{ fontSize: "15px", color: "#000", maxWidth: 800 }}
          // sx={{ fontSize: "15px", color: isDarkMode ? "#fff" : "#000" }}
        >
          Salamat sa pag-order mula sa Growsari! Tatawagan ka namin sa loob ng 2 hours* para i-confirm
          ang iyong order at delivery date.
        </Item>

        <PageTimer />
      </Stack>
      <Grid container justifyContent="center">
        <Item
          flex={1}
          px={{
            xs: 3,
            sm: 5,
          }}
          py={3}
          sx={{ maxWidth: 800 }}>
          <Stack spacing={2.5}>
            <ItemInfo label="Reference #" description={referenceNo} />
            {+last_order_totalCost ? (
              <ItemInfo
                label="Order amount"
                description={`₱${numeral(totalCost || last_order_totalCost).format("0,0.00")}`}
              />
            ) : null}
            <ItemInfo
              label="Delivery fee"
              description={
                <Chip size="small" sx={{ bgcolor: "#DFF2EE", color: "#007358" }} label="FREE" />
              }
            />
            <Divider sx={{ opacity: 0.5 }} />
            <ItemInfo
              label="Estimated total (COD)"
              description={
                +last_order_totalCost ? (
                  <Item className="tx700 tx100 c4a">{`₱${numeral(
                    totalCost || last_order_totalCost
                  ).format("0,0.00")}`}</Item>
                ) : (
                  <Item>To be confirmed</Item>
                )
              }
            />
          </Stack>
        </Item>
      </Grid>
      <Stack
        alignItems="center"
        className="tx300 ls75"
        sx={{
          fontSize: "15px",
          bgcolor: "#fff",
          color: "#000",
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: 80,
        }}>
        <Item sx={{ maxWidth: 260, textAlign: "center", py: 1.5 }}>
          *For orders placed between 8AM-5PM, Mon-Sat
        </Item>
      </Stack>
    </>
  );
};

const ItemInfo = ({ label, description }) => (
  <Grid container justifyContent="space-between">
    <Item className="ls75 c3" sx={{ fontSize: "15.5px", maxWidth: "50%" }}>
      {label}
    </Item>
    <Item className="tx95 c4" sx={{ letterSpacing: "0.5px" }}>
      {description}
    </Item>
  </Grid>
);

export default OrderStatusPage;
