import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ed5a29",
      // secondary: "#444262",
      // light: "#4f4f4f",
      // dark: "#262338",
      // contrastText: "#262338",
    },
    secondary: {
      main: "#d5d5d5",
      // light: "#959595",
      // dark: "#dddddd",
      // contrastText: "#191919",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // custom: 1400,
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // fontSize: "0.75rem",
          fontSize: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: `8px` },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "#26233866",
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: `8px`,
    //       border: "white 1px solid",
    //       "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    //         display: "none",
    //       },
    //       "& input[type=number]": {
    //         MozAppearance: "textfield",
    //       },
    //       "& input:-internal-autofill-selected": {
    //         background: "none",
    //       },
    //       "&.Mui-focused fieldset": {
    //         border: "1px #fff !important",
    //       },
    //     },
    //   },
    // },
  },
});

export const themeInput = createTheme({
  // direction: "rtl",
  palette: {
    primary: {
      main: "#242424",
      // secondary: "#444262",
      // light: "#4f4f4f",
      // dark: "#191919",
      // contrastText: "#ffffff",
    },
    secondary: {
      main: "#d5d5d5",
      // light: "#959595",
      // dark: "#dddddd",
      // contrastText: "#191919",
    },
  },
  components: {
    typography: {
      fontFamily: "Poppins !important",
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: "transparent !important",
          color: "red",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              // borderColor: "transparent",
              borderRadius: 16,
            },
            "&:hover fieldset": {
              // borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              // borderColor: "transparent",
            },
            "& .MuiInputLabel-shrink": {
              // display: "transparent",
              // width: 0,
            },
            "& .MuiOutlinedInput-input": {
              // fontSize: "0.95rem", // Adjust the font size as needed
              fontSize: "16px",
              fontFamily: "Poppins",
            },
          },
        },
      },
    },
  },
});

export const themeNudge = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14.5px",
          fontWeight: 400,
          color: "#fff",
          backgroundColor: "#07a69c",
          maxWidth: 180,
          fontFamily: "Poppins",
          borderRadius: "12px",
          padding: "12px 16px",
          left: "10px",
        },
        arrow: {
          "&:before": {
            border: "1px solid #E6E8ED",
          },
          color: "#07a69c",
          marginLeft: "-10px",
        },
      },
    },
  },
});

export default theme;
