import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import categories from "../../assets/data/categories.json";
import { useReactiveVar } from "@apollo/client";
import { activeCategoryVar, additionItemModalVar } from "../../graphql/reactiveVars";
import { cartItemsVar, loadingLocalVar } from "../../graphql/reactiveVars";
import { cartAdditionItemsVar, categoryVar, skuListVar, sourceVar } from "../../graphql/reactiveVars";
import Item from "../atoms/Item";
import ProductCard from "../organisms/ProductCard";
import NextButton from "../atoms/NextButton";
import { useNavigate } from "react-router-dom";
import { getProductsByCategory } from "../../graphql/actions/productActions";
import { useAnalytics, PageLabels } from "../../analytics";
import PageTimer from "../../analytics/PageTimer";
import AddOtherItemsSmall from "../molecules/AddOtherItemsSmall";
import SwipeableDrawer2 from "../atoms/SwipeableDrawer2";
import NextButtonWithOtherItem from "../atoms/NextButtonWithOtherItem";
import AddOtherItemsEnd from "../molecules/AddOtherItemsEnd";
import { useRef } from "react";

const ProductPage = () => {
  const { fireViewScreenEvent, fireScrollScreenEvent } = useAnalytics({
    pageLabel: PageLabels.PRODUCT_LIST,
  });
  const selectedCategory = useReactiveVar(categoryVar);
  const navigate = useNavigate();
  const skuList = useReactiveVar(skuListVar);
  const loading = useReactiveVar(loadingLocalVar);
  const [hasScrolled, setHasScrolled] = useState(false);
  const cartItems = useReactiveVar(cartItemsVar);
  const lastDivRef = useRef(null);
  const cartAdditionItems = useReactiveVar(cartAdditionItemsVar);
  const containerRef = useRef(null);

  const onReviewCart = () => navigate(`/cart`);

  const handleClick = (targetCategory) => {
    const targetElement = document.getElementById(targetCategory);
    if (targetElement) {
      const scrollPosition = targetElement.offsetTop - 85; // Subtract 150px offset
      window.scrollTo({ top: scrollPosition });
      categoryVar(targetCategory);
      activeCategoryVar(targetCategory);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled- if they've scrolled 100 pixels.
      if (window.scrollY > 50) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };
    // Attach the event listener when the component mounts.
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts.
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (hasScrolled) {
      fireScrollScreenEvent(PageLabels.PRODUCT_LIST);
    }
  }, [hasScrolled, fireScrollScreenEvent]);

  // ----------------------------------------------------------------

  useEffect(() => {
    handleClick(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    const storedUUID = localStorage.getItem("UserUUID");
    if (!storedUUID) {
      // Generate and store the UUID
      const uuid = uuidv4();
      localStorage.setItem("UserUUID", uuid);
    }
    fireViewScreenEvent(PageLabels.PRODUCT_LIST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireViewScreenEvent]);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        sourceVar("End of List Pull");
        !cartItemsVar()?.length && additionItemModalVar(true);
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin
      threshold: 0.1, // 10% of the element is visible
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (lastDivRef.current) {
      observer.observe(lastDivRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (lastDivRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return observer.unobserve(lastDivRef?.current);
      }
    };
  }, []);

  const scrollToBottom = async () => {
    await window.scrollTo({ top: containerRef.current.scrollHeight });
  };

  return (
    <Stack ref={containerRef} className="bg_gray" sx={{ minHeight: "150vh" }}>
      {/* {source.match(/end of list pull/gi) ? <SwipeableDrawerPull /> : <SwipeableDrawer2 />} */}
      <Stack sx={{ px: 1, mb: 6, minWidth: "calc(100vw - 75px)" }}>
        <Box sx={{ height: !cartItems?.length ? 60 : 10 }} />
        {categories.map((category, index) => (
          <Stack
            key={category.id}
            spacing={1.25}
            onTouchStart={() => activeCategoryVar(category.id)}
            onMouseOver={() => activeCategoryVar(category.id)}>
            <Item className="tx85 tx600" id={category.id} pt={0.75} px={0.75}>
              {category.name}
            </Item>
            <Stack spacing={0.75}>
              {getProductsByCategory(category.id, skuList)?.map((data, id) => (
                <ProductCard key={id} data={data} />
              ))}
              {!getProductsByCategory(category.id, skuList)?.length && (
                <Item className="tx85" sx={{ bgcolor: "#fff", minHeight: "60px" }} px={1.5} py={2}>
                  {/* {!loading && "No data"} */}
                  {!loading && <Box className="c3">Loading...</Box>}
                </Item>
              )}
            </Stack>
            {index + 1 === categories.length ? (
              <Box sx={{ height: 80 }}>
                <AddOtherItemsEnd action={scrollToBottom} />
              </Box>
            ) : (
              <AddOtherItemsSmall source={category.name} skuList />
            )}
          </Stack>
        ))}
        <NextButtonWithOtherItem label="Proceed to total" action={onReviewCart} />
        <NextButton label="Proceed to total" action={onReviewCart} />
        <PageTimer />
      </Stack>
      <Stack
        className="bg_gray"
        sx={{ height: cartItems?.length || cartAdditionItems?.length ? "75px" : "5px" }}
      />
      <Stack className="bg_gray" ref={lastDivRef} sx={{ height: "5px" }} />
      <Stack className="bg_gray" sx={{ height: "15px" }} />
      <SwipeableDrawer2 />
    </Stack>
  );
};

export default ProductPage;
