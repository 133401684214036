import { Stack } from "@mui/material";
import { useWidth } from "../components/atoms/helperFunc/useWidth";

export const BackIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.2916 16C25.2916 16.75 24.7083 17.3334 24 17.3334H11.2083L15.5833 21.75C16.125 22.25 16.125 23.125 15.5833 23.625C15.3333 23.875 15 24 14.6666 24C14.2916 24 13.9583 23.875 13.7083 23.625L7.04163 16.9584C6.49996 16.4584 6.49996 15.5834 7.04163 15.0834L13.7083 8.41669C14.2083 7.87502 15.0833 7.87502 15.5833 8.41669C16.125 8.91669 16.125 9.79169 15.5833 10.2917L11.2083 14.6667H24C24.7083 14.6667 25.2916 15.2917 25.2916 16Z"
      fill="#ED5A29"
    />
  </svg>
);

export const CloseIcon = ({ fill = "#6E7191", size = "24" }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6875 15.3125C17.0938 15.6875 17.0938 16.3438 16.6875 16.7188C16.5 16.9062 16.25 17 16 17C15.7188 17 15.4688 16.9062 15.2812 16.7188L12 13.4375L8.6875 16.7188C8.5 16.9062 8.25 17 8 17C7.71875 17 7.46875 16.9062 7.28125 16.7188C6.875 16.3438 6.875 15.6875 7.28125 15.3125L10.5625 12L7.28125 8.71875C6.875 8.34375 6.875 7.6875 7.28125 7.3125C7.65625 6.90625 8.3125 6.90625 8.6875 7.3125L12 10.5938L15.2812 7.3125C15.6562 6.90625 16.3125 6.90625 16.6875 7.3125C17.0938 7.6875 17.0938 8.34375 16.6875 8.71875L13.4062 12.0312L16.6875 15.3125Z"
      fill={fill}
    />
  </svg>
);

export const CheckIcon = ({ size = "24" }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 12C4 7.59375 7.5625 4 12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12ZM15.5938 10.625C15.9375 10.2812 15.9375 9.75 15.5938 9.40625C15.25 9.0625 14.7188 9.0625 14.375 9.40625L11 12.7812L9.59375 11.4062C9.25 11.0625 8.71875 11.0625 8.375 11.4062C8.03125 11.75 8.03125 12.2812 8.375 12.625L10.375 14.625C10.7188 14.9688 11.25 14.9688 11.5938 14.625L15.5938 10.625Z"
      fill="#007358"
    />
  </svg>
);

export const AnimatedIconRight = () => {
  const { width } = useWidth();

  return (
    <>
      <Stack>
        <svg width={width} height="72" viewBox={`0 0 ${width} 72`} xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M0 12C0 5.37258 5.37258 0 12 0H${width - 12}C${
              width - 5.37258
            } 0 ${width} 5.37258 ${width} 12V72H0V12`}
            fill="#FCF4F3"
          />
        </svg>
      </Stack>
      <Stack sx={{ position: "absolute", right: 0, bottom: 0 }}>
        <svg width="214" height="72" viewBox="0 0 214 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 0H202.059C208.654 0 214 5.37258 214 12V72H13V0Z" fill="#ED5A29" />
          <path d="M51 37L12 82.0333V-8.03333L51 37Z" fill="#FCF4F3" />
          <path d="M54 37L15 82.0333V-8.03333L54 37Z" fill="#FCF4F3" />
          <path
            className="sliding"
            d="M44 37L5 82.0333L-5.5 75.5V-0.999995L5 -8.03333L44 37Z"
            fill="#ED5A29"
          />
          <path d="M32 37L-7 82.0333V-8.03333L32 37Z" fill="#FCF4F3" />
        </svg>
      </Stack>
    </>
  );
};

export const RebateIcon = () => (
  <svg width="55" height="16" viewBox="0 0 55 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.32486 1.63059C0.793875 0.977077 1.25894 0 2.10097 0H52.899C53.7411 0 54.2061 0.977078 53.6751 1.63059L49.0124 7.36941C48.7138 7.73681 48.7138 8.26319 49.0124 8.63059L53.6751 14.3694C54.2061 15.0229 53.7411 16 52.899 16H2.10097C1.25894 16 0.793876 15.0229 1.32486 14.3694L5.98764 8.63059C6.28616 8.26319 6.28616 7.73681 5.98764 7.36941L1.32486 1.63059Z"
      fill="#ED5A29"
    />
    <path
      d="M10.36 5.5V4.21H12.77V11.5H11.33V5.5H10.36ZM14.903 7.76C14.903 6.60667 15.1097 5.70333 15.523 5.05C15.943 4.39667 16.6364 4.07 17.603 4.07C18.5697 4.07 19.2597 4.39667 19.673 5.05C20.093 5.70333 20.303 6.60667 20.303 7.76C20.303 8.92 20.093 9.83 19.673 10.49C19.2597 11.15 18.5697 11.48 17.603 11.48C16.6364 11.48 15.943 11.15 15.523 10.49C15.1097 9.83 14.903 8.92 14.903 7.76ZM18.923 7.76C18.923 7.26667 18.8897 6.85333 18.823 6.52C18.763 6.18 18.6364 5.90333 18.443 5.69C18.2564 5.47667 17.9764 5.37 17.603 5.37C17.2297 5.37 16.9464 5.47667 16.753 5.69C16.5664 5.90333 16.4397 6.18 16.373 6.52C16.313 6.85333 16.283 7.26667 16.283 7.76C16.283 8.26667 16.313 8.69333 16.373 9.04C16.433 9.38 16.5597 9.65667 16.753 9.87C16.9464 10.0767 17.2297 10.18 17.603 10.18C17.9764 10.18 18.2597 10.0767 18.453 9.87C18.6464 9.65667 18.773 9.38 18.833 9.04C18.893 8.69333 18.923 8.26667 18.923 7.76ZM22.1179 7.76C22.1179 6.60667 22.3246 5.70333 22.7379 5.05C23.1579 4.39667 23.8512 4.07 24.8179 4.07C25.7846 4.07 26.4746 4.39667 26.8879 5.05C27.3079 5.70333 27.5179 6.60667 27.5179 7.76C27.5179 8.92 27.3079 9.83 26.8879 10.49C26.4746 11.15 25.7846 11.48 24.8179 11.48C23.8512 11.48 23.1579 11.15 22.7379 10.49C22.3246 9.83 22.1179 8.92 22.1179 7.76ZM26.1379 7.76C26.1379 7.26667 26.1046 6.85333 26.0379 6.52C25.9779 6.18 25.8512 5.90333 25.6579 5.69C25.4712 5.47667 25.1912 5.37 24.8179 5.37C24.4446 5.37 24.1612 5.47667 23.9679 5.69C23.7812 5.90333 23.6546 6.18 23.5879 6.52C23.5279 6.85333 23.4979 7.26667 23.4979 7.76C23.4979 8.26667 23.5279 8.69333 23.5879 9.04C23.6479 9.38 23.7746 9.65667 23.9679 9.87C24.1612 10.0767 24.4446 10.18 24.8179 10.18C25.1912 10.18 25.4746 10.0767 25.6679 9.87C25.8612 9.65667 25.9879 9.38 26.0479 9.04C26.1079 8.69333 26.1379 8.26667 26.1379 7.76Z"
      fill="white"
    />
    <circle cx="37" cy="8" r="6" fill="white" />
    <path
      d="M37 11.3334C35.1641 11.3334 33.6667 9.83592 33.6667 8.00002C33.6667 6.16412 35.1641 4.66669 37 4.66669C37.9539 4.66669 38.8667 5.07694 39.5026 5.79489L38.7334 6.47182C38.2923 5.97951 37.6667 5.69233 37 5.69233C35.7282 5.69233 34.6923 6.72823 34.6923 8.00002C34.6923 9.27181 35.7282 10.3077 37 10.3077C38.0975 10.3077 39.0205 9.53848 39.2462 8.51284H37V7.4872H40.3334V8.00002C40.3334 9.83592 38.8359 11.3334 37 11.3334Z"
      fill="#ED5A29"
    />
  </svg>
);
