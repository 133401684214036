import React, { useEffect, useState } from "react";
import Item from "./Item";
import { Skeleton } from "@mui/material";
import gs_logo from "../../assets/images/gs-logo.png";

const ProductImage = ({ data }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    if (data.image_url) {
      const img = new Image();
      img.src = data.image_url;

      img.onload = () => {
        // Check if the component is still mounted before setting the state
        if (isMounted) {
          setIsImageLoaded(true);
        }
      };

      img.onerror = () => {
        // Check if the component is still mounted before setting the state
        if (isMounted) {
          setIsImageLoaded(false);
          setError(true);
        }
      };
    }

    // Cleanup function to cancel image loading if the component unmounts
    return () => {
      isMounted = false; // Set the flag to false to indicate unmounting
      // You can add logic here to cancel the image loading if needed
    };
  }, [data.image_url]);

  return (
    <>
      {isImageLoaded ? (
        <Item
          sx={{
            minWidth: "54px",
            minHeight: "54px",
            bgcolor: "#EFF0F6",
            backgroundImage: `url("${data.image_url}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "2px",
            opacity: 0.95,
            border: "0.85px dashed lightgray",
          }}
        />
      ) : error ? (
        <Item
          sx={{
            minWidth: "54px",
            minHeight: "54px",
            // bgcolor: "#EFF0F6",
            backgroundImage: `url("${gs_logo}")`,
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "2px",
            opacity: 0.95,
            border: "0.85px dashed lightgray",
          }}
        />
      ) : (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={54}
          height={54}
          sx={{ borderRadius: "2px", bgcolor: "#EFF0F6" }}
        />
      )}
    </>
  );
};

export default ProductImage;
