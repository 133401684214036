/* eslint-disable no-undef */
const metapixelInitiateCheckout = () => {
  try {
    fbq("track", "InitiateCheckout");
  } catch (error) {
    console.log("error");
  }
};

const metapixelViewContent = () => {
  try {
    fbq("track", "ViewContent");
  } catch (error) {
    console.log("error");
  }
};

const metapixelAddPaymentInfo = () => {
  try {
    fbq("track", "AddPaymentInfo");
  } catch (error) {
    console.log("error");
  }
};

const metapixelPurchase = (value = 0) => {
  try {
    fbq("track", "Purchase", { value: value, currency: "PHP" });
  } catch (error) {
    console.log("error");
  }
};

const metapixelEvents = {
  metapixelPurchase,
  metapixelAddPaymentInfo,
  metapixelInitiateCheckout,
  metapixelViewContent,
};

export default metapixelEvents;
