/* eslint-disable jsx-a11y/alt-text */
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Helmet as MetaHelmet } from "react-helmet";
import theme from "./assets/theme";
import AppDrawer from "./components/atoms/AppDrawer";
import { useEffect } from "react";
import CartPage from "./components/pages/CartPage";
import ConfirmOrderPage from "./components/pages/ConfirmOrderPage";
import OrderStatusPage from "./components/pages/OrderStatusPage";
import { getLocalData } from "./graphql/actions/uiActions";
import TermsCondition from "./components/pages/TermsCondition";
import { GA_MEASUREMENT_ID, PIXEL_ID } from "./config";
import localforage from "localforage";
import NoMatch from "./components/pages/NoMatch";
import HowToOrder from "./components/pages/HowToOrder";
import { runFullStoryVar } from "./graphql/reactiveVars";
import BlocksGame from "./components/pages/BlocksGame";

const App = () => {
  const { search } = useLocation();

  useEffect(() => {
    const runFullStory = sessionStorage.getItem("runFullStory") === "true";
    runFullStoryVar(runFullStory);

    localforage.getItem("clientInfoItems").then((x) => {
      if (!!search.match(/utm/gi)) {
        sessionStorage.setItem("utm", search);
      }
      getLocalData();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Layout = () => <Outlet />;

  const MetaPixel = () => {
    return (
      <MetaHelmet>
        <script>
          {`
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
          if (!window.location.origin.match(/localhost/)) {
            fbq("init", "${PIXEL_ID}");
            fbq("track", "PageView");
          }
      `}
        </script>
        <noscript>{`
          <img
            height="1"
            width="1"
            style="display: none"
            src="https://www.facebook.com/tr?id=621802908538307&ev=PageView&noscript=1"
          />
        `}</noscript>
      </MetaHelmet>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <MetaPixel />
      <HelmetProvider>
        {!window.location.origin.match(/localhost/gi) && GA_MEASUREMENT_ID && (
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
            />
            <script>
              {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments)
                  }
                gtag('js', new Date());
                gtag('config', '${GA_MEASUREMENT_ID}');`}
            </script>
          </Helmet>
        )}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HowToOrder />} />
            <Route path="products" element={<AppDrawer />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="confirm-order" element={<ConfirmOrderPage />} />
            <Route path="order-status" element={<OrderStatusPage />} />
            <Route path="terms-conditions" element={<TermsCondition />} />
          </Route>
          <Route path="mini-game" element={<Layout />}>
            <Route index element={<BlocksGame />} />
            <Route path="blocks" element={<BlocksGame />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
