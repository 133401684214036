import { useEffect } from "react";

const PageTimer = () => {
  useEffect(() => {
    sessionStorage.setItem("StartTime", new Date());

    return () => {
      const endTime = new Date();
      const prevStartTime = new Date(sessionStorage.getItem("StartTime"));
      const timeSpent = (endTime - prevStartTime) / 1000;
      sessionStorage.setItem("TimeSpent", timeSpent + "s");
    };
  }, []);

  return null; // Since it's not rendering anything
};

export default PageTimer;
