import { Button, Divider, Stack } from "@mui/material";
import Item from "../atoms/Item";
import bagsak_presyo from "../../assets/images/bagsak_presyo.svg";
import { additionItemModalVar, sourceVar } from "../../graphql/reactiveVars";
import { useReactiveVar } from "@apollo/client";

const AddOtherItemsEnd = ({ action }) => {
  const additionItemModal = useReactiveVar(additionItemModalVar);

  const showModal = async () => {
    await action();
    await additionItemModalVar(!additionItemModal);
    await sourceVar("End of List");
  };

  return (
    <Stack sx={{ height: 80 }} px={0} py={0.25} className={`pointer`}>
      <Divider className="tx70 tx500 ls25" sx={{ color: "#A0A1AD", mb: 1 }}>
        End of list
      </Divider>
      <Stack>
        <Item
          sx={{
            height: 80,
            opacity: 0.95,
            width: "calc(100vw - 90px)",
            position: "absolute",
            background: `linear-gradient(95deg, #008577 12.76%, #04968A 54.5%, #07A69C 103.92%)`,
          }}
        />
        <Item
          flex={1}
          sx={{
            height: 80,
            width: "calc(100vw - 90px)",
            position: "absolute",
            backgroundImage: `url(${bagsak_presyo})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            border: "1px solid #C5BFDB",
          }}
        />
        <Stack px={1.5} spacing={0.5} alignItems="start" sx={{ zIndex: 2, pt: 1 }}>
          <Item className="tx700 c5" sx={{ fontSize: "15px" }} flex={1}>
            May iba pang hinahanap?
          </Item>
          <Button
            size="small"
            variant="contained"
            className="unset tx600 tx75 hover_white"
            sx={{ py: 0.75 }}>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.16663 7.99996C3.16663 5.06246 5.54163 2.66663 8.49996 2.66663C11.4375 2.66663 13.8333 5.06246 13.8333 7.99996C13.8333 10.9583 11.4375 13.3333 8.49996 13.3333C5.54163 13.3333 3.16663 10.9583 3.16663 7.99996ZM8.49996 10.3333C8.77079 10.3333 8.99996 10.125 8.99996 9.83329V8.49996H10.3333C10.6041 8.49996 10.8333 8.29163 10.8333 7.99996C10.8333 7.72913 10.6041 7.49996 10.3333 7.49996H8.99996V6.16663C8.99996 5.89579 8.77079 5.66663 8.49996 5.66663C8.20829 5.66663 7.99996 5.89579 7.99996 6.16663V7.49996H6.66663C6.37496 7.49996 6.16663 7.72913 6.16663 7.99996C6.16663 8.29163 6.37496 8.49996 6.66663 8.49996H7.99996V9.83329C7.99996 10.125 8.20829 10.3333 8.49996 10.3333Z"
                fill="#07A69C"
              />
            </svg>
            <Item pl={0.75} onClick={showModal}>
              Add other items
            </Item>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddOtherItemsEnd;
