import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Item from "../atoms/Item";

function NoMatch() {
  return (
    <Box p={3}>
      <Item className="tx400 c3">Nothing to see here!</Item>
      <p>
        <Link to="/">
          <Item className="tx400 c3">Go to the home page</Item>
        </Link>
      </p>
    </Box>
  );
}

export default NoMatch;
