import { Stack } from "@mui/material";
import React from "react";
import Item from "./Item";
import bg_welcome from "../../assets/images/bg_welcome.png";

const MobileSize = ({ children, bgColor }) => (
  <Stack width="100vw" alignItems="center">
    <Item
      sx={{
        maxWidth: 425,
        width: "100%",
        minHeight: "100vh",
        background: `url(${bg_welcome}) ${bgColor} 50% / cover no-repeat`,
      }}>
      {children}
    </Item>
  </Stack>
);

export default MobileSize;
